import React from 'react'
import { useAuth } from '../../context/auth'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

const AdminHeader = () => {
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate() // Use the useNavigate hook

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: '',
    })
    localStorage.removeItem('auth')

    // Navigate to login page with state
    navigate('/login', { state: { loggedOut: true } })
  }

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      variant='dark'
      fixed='top'
      style={{ backgroundColor: 'rgb(47, 68, 131)' }}
    >
      <Container>
        <Navbar.Brand href='/admin/dashboard'>WMS</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            {auth?.user && (
              <Link
                className='navbar-brand'
                style={{ color: 'white', textDecoration: 'none' }}
                to={
                  auth.user.role === 1
                    ? '/admin/dashboard'
                    : '/dashboard/cashier'
                }
              >
                {auth.user.role === 1 ? 'Admin Dashboard' : 'Cashier Dashboard'}
              </Link>
            )}
          </Nav>
          <Nav
            className='me-auto'
            style={{ color: 'white', textDecoration: 'none' }}
          >
            User: {auth?.user?.name}
          </Nav>

          <Nav>
            {auth?.user ? (
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            ) : (
              <Nav.Link href='/login'>Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AdminHeader
