import React, { useEffect, useState } from 'react'
import useInfiniteScroll from './useInfiniteScroll'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import '../../../styles/AdminStyle.css'
import moment from 'moment'
import { useAuth } from '../../../context/auth'
import { useNavigate } from 'react-router-dom'

const AdminStockLog = () => {
  const navigate = useNavigate()
  const [auth, setAuth] = useAuth()
  const [items, setItems] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const [page, setPage] = useState(0)
  const [HasMore, setHasMore] = useState(true)

  const [lastElementRef] = useInfiniteScroll(
    HasMore ? loadMoreItems : () => {},
    isFetching
  )

  useEffect(() => {
    loadMoreItems()
  }, [])

  function loadMoreItems() {
    setIsFetching(true)

    axios({
      method: 'GET',
      url: '/api/v1/warehouse/stock-logs-list',
      params: { _page: page, _limit: 30 },
    })
      .then((res) => {
        setItems((prev) => {
          return [...new Set([...prev, ...res.data.response.items])]
        })

        setPage((prevPageNumber) => prevPageNumber + 1)
        setHasMore(res.data.response.items.length > 0)
        setIsFetching(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleStockUpdate = async (e, itemId) => {
    e.preventDefault()

    try {
      const { data } = await axios.put(
        '/api/v1/warehouse/update-stock-record',
        { itemId }
      )

      let index = items.findIndex((item) => item._id === itemId)
      let newStatus = 'Approved'
      let updatedItems = {
        ...items[index],
        status: newStatus,
      }
      const newItems = [...items]
      newItems[index] = updatedItems
      setItems(newItems)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteConfirmation = () => {
    const result = window.confirm('Confirm Delete?')
    return result
  }

  const deleteSubmit = async (stockId) => {
    const isConfirm = deleteConfirmation()

    if (isConfirm) {
      try {
        const { data } = await axios.post('/api/v1/warehouse/delete-bookmark', {
          stockId,
        })
        setItems(items.filter((item) => item._id !== stockId))
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Layout title={'Stock Logs'}>
      <div
        className='align-items-center searchInventory shadow-sm p-3 bg-white rounded'
        style={{ display: 'flex', width: '100%' }}
      >
        <div className='col-auto text-end' style={{ width: '100%' }}>
          <button
            type='button'
            class='btn btn-primary btn-sm'
            onClick={() => navigate('/admin/warehouse')}
          >
            Return
          </button>
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '80px' }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Item</th>
                <th>Rack</th>
                <th>Box</th>
                <th>Logs</th>
                <th>Remark</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                if (items.length === index + 1) {
                  return (
                    <tr ref={lastElementRef} key={index} className='endData'>
                      <td
                        colSpan={8}
                        className='text-center'
                        style={{ color: '#19692c' }}
                      >
                        Items retrieve completed
                      </td>
                    </tr>
                  )
                } else {
                  return (
                    <>
                      <tr className='itemMainInfo' key={index}>
                        <td>
                          {moment(item.takenDate)
                            .subtract(8, 'hours')
                            .format('YYYY-MM-DD HH:mm')}
                        </td>
                        <td className='stock-description-cell'>
                          <span className='me-2 fw-bold'>{item.itemCode}</span>
                          {item.description}
                        </td>
                        <td>{item.rackNo}</td>
                        <td>{item.boxNo}</td>
                        <td>
                          {item.user.code} take{' '}
                          <span className='fw-bold'>{item.quantity}</span> qty.
                        </td>
                        <td>{item.remark}</td>
                        <td>
                          {item.status === 2 ? (
                            <span style={{ color: '#d39e00' }}>Pending</span>
                          ) : (
                            <span style={{ color: '#1e7e34' }}>Approved</span>
                          )}
                        </td>
                        <td>
                          {(item.status === 2 &&
                            auth?.user?._id === '658e28cb173937c3f849f2ce') ||
                            (item.status === 2 &&
                              auth?.user?._id ===
                                '65960e5fc2b5e5e3557ff94f' && (
                                <button
                                  type='submit'
                                  class='btn btn-outline-success btn-sm'
                                  onClick={(e) =>
                                    handleStockUpdate(e, item._id)
                                  }
                                >
                                  <i class='bi bi-check-lg'></i>
                                </button>
                              ))}

                          {auth?.user._id === item.user._id &&
                            item.status === 2 && (
                              <span
                                className='ms-3'
                                style={{ cursor: 'pointer' }}
                                onClick={() => deleteSubmit(item._id)}
                              >
                                <i
                                  class='bi bi-trash3'
                                  style={{ color: '#dc3545' }}
                                ></i>
                              </span>
                            )}
                        </td>
                      </tr>
                    </>
                  )
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default AdminStockLog
