import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

const UpdateStatusModal = ({
  show,
  handleClose,
  currentStatus,
  updateStatus,
}) => {
  const [status, setStatus] = useState(currentStatus)

  useEffect(() => {
    if (show) {
      setStatus(currentStatus.toString())
    }
  }, [currentStatus, show])

  const handleSubmit = (e) => {
    e.preventDefault()
    updateStatus(parseInt(status))
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className='d-flex justify-content-between'>
            {['Delivered', 'Shipped', 'Pending Shipment'].map(
              (label, index) => (
                <Form.Check
                  key={index}
                  type='radio'
                  label={label}
                  name='status'
                  id={`status-${index + 1}`}
                  value={index + 1}
                  checked={status === (index + 1).toString()}
                  onChange={(e) => setStatus(e.target.value)}
                  className='mb-2 me-2'
                />
              )
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={handleSubmit}
          style={{ marginRight: '20px' }}
        >
          Update
        </Button>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateStatusModal
