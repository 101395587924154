import React, { useState } from 'react'
import axios from 'axios'

const AdminEditNonAutoCountSelectData = ({
  value,
  onSave,
  currentCategoryId,
}) => {
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(currentCategoryId)
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [categoryName, setCategoryName] = useState(value) // Holds the displayed category name

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(
        '/api/v1/admin/non-autocount/get-category'
      )
      setCategories(data)
    } catch (error) {
      console.error('Error fetching categories:', error)
    }
  }

  const handleSelectCategory = async (selectedCategory) => {
    setSelectedCategory(selectedCategory._id)
    setCategoryName(selectedCategory.categoryName)
    setShowCategoryDropdown(false)
    setIsEditing(false)
    await onSave(selectedCategory)
  }

  const handleEditClick = async () => {
    setIsEditing(true)
    await fetchCategories()
    setShowCategoryDropdown(true)
  }

  return (
    <td>
      {isEditing ? (
        <div style={{ position: 'relative' }}>
          <select
            value={selectedCategory}
            onChange={(e) => {
              const selectedCat = categories.find(
                (cat) => cat._id === e.target.value
              )
              handleSelectCategory(selectedCat)
            }}
            onBlur={() => setIsEditing(false)}
            autoFocus
            style={{ width: '100%' }}
          >
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <span onClick={handleEditClick} style={{ cursor: 'pointer' }}>
          {categoryName || (
            <i
              className='bi bi-pencil-fill'
              style={{
                cursor: 'pointer',
                color: 'grey',
                fontSize: '12px',
              }}
            ></i>
          )}
        </span>
      )}
    </td>
  )
}

export default AdminEditNonAutoCountSelectData
