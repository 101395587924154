const UserStoreRoomCheckListTable = ({
  items,
  editMode,
  handleQtyChange,
  saveQty,
  handleQtyKeyPress,
  toggleEdit,
  openLogModal,
  lastElementRef,
  handleRemarkChange,
  handleRemarkKeyPress,
  saveRemark,
}) => {
  function formatDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <table className='table actable' style={{ marginTop: '130px' }}>
      <thead>
        <tr>
          <th>Item Code</th>
          <th>Desc</th>
          <th className='text-center'>Qty</th>
          <th className='text-center'>UOMRate</th>
          <th className='text-center'>Total</th>
          <th className='text-center'>Physical Qty</th>
          <th>Info</th>
          <th>Remark</th>
          <th>Last Updated</th>
        </tr>
      </thead>
      <tbody>
        {items.length > 0 ? (
          items.map((item, index) => (
            <tr
              key={item._id}
              ref={index === items.length - 1 ? lastElementRef : null}
            >
              <td>{item.itemCode}</td>
              <td>{item.description}</td>
              <td className='text-center'>{item.quantity}</td>
              <td className='text-center'>{item.uomRate}</td>
              <td className='text-center'>{item.quantity * item.uomRate}</td>
              <td className='text-center'>
                {editMode === item._id ? (
                  <input
                    type='number'
                    className='form-control'
                    style={{ width: '100px', margin: 'auto' }}
                    value={item.checkQty}
                    onChange={(e) =>
                      handleQtyChange(item._id, e.target.value, 'checkQty')
                    }
                    onBlur={() => saveQty(item._id, 'checkQty')}
                    onKeyDown={(e) =>
                      handleQtyKeyPress(e, item._id, 'checkQty')
                    }
                    onFocus={(e) => e.target.select()}
                    min='0'
                    autoFocus
                  />
                ) : (
                  <span
                    onClick={() =>
                      toggleEdit(item._id, 'checkQty', item.checkQty)
                    }
                    style={{
                      cursor: 'pointer',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                  >
                    {item.checkQty}
                  </span>
                )}
              </td>
              <td>{item.remark}</td>
              <td>
                {editMode === `${item._id}-userRemark` ? (
                  <input
                    type='text'
                    className='form-control'
                    value={item.userRemark}
                    onChange={(e) =>
                      handleRemarkChange(item._id, e.target.value)
                    }
                    onBlur={() => saveRemark(item._id)} // Save on blur
                    onKeyDown={(event) => handleRemarkKeyPress(event, item._id)} // Handle Enter key press
                    autoFocus
                  />
                ) : (
                  <span
                    onClick={() =>
                      toggleEdit(
                        `${item._id}-userRemark`,
                        'userRemark',
                        item.userRemark
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {item.userRemark ? (
                      item.userRemark
                    ) : (
                      <i
                        className='bi bi-pencil-fill'
                        style={{
                          cursor: 'pointer',
                          color: 'grey',
                          fontSize: '12px',
                        }}
                      ></i>
                    )}
                  </span>
                )}
              </td>
              <td>
                {item.lastUptByCode && (
                  <>
                    <span style={{ cursor: 'pointer', marginRight: '10px' }}>
                      <i
                        className='bi-info-circle-fill'
                        style={{ color: '#00a6ff' }}
                        onClick={() => openLogModal(item._id)}
                      ></i>
                    </span>
                    {item.lastUptByCode}{' '}
                    {item.lastUptDate ? formatDate(item.lastUptDate) : ''}
                  </>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan='8'>No items found</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default UserStoreRoomCheckListTable
