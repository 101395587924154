import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../context/auth'
import axios from 'axios'

const AdminEditPreOrderBook = () => {
  const { id } = useParams() // Use useParams to get the book ID from the URL
  const [title, setTitle] = useState('')
  const [vol, setVol] = useState('')
  const [lastVol, setLastVol] = useState('')
  const [status, setStatus] = useState(1)
  const [auth] = useAuth()

  const navigate = useNavigate()

  // Fetch the pre-book data
  useEffect(() => {
    const fetchPreBook = async () => {
      try {
        const token = auth?.token
        const { data } = await axios.get(`/api/v1/admin/prebook/get/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        if (data?.success) {
          setTitle(data.book.title)
          setVol(data.book.vol)
          setLastVol(data.book.lastVol)
          setStatus(data.book.status)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchPreBook()
  }, [id, auth])

  const handleUpdate = async (e) => {
    e.preventDefault()
    try {
      const token = auth?.token
      const updateData = { title, vol, lastVol, status }
      const { data } = await axios.put(
        `/api/v1/admin/prebook/edit/${id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (data?.success) {
        navigate('/admin/pre-order/books/list')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout title={'Edit Pre-order Book'}>
      <div className='pt-3 mx-3'>
        <div className='text-end'>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/admin/pre-order/books/list')}
          >
            Back
          </button>
        </div>

        <section className='h-100'>
          <div className='container h-100'>
            <div className='row justify-content-sm-center h-100'>
              <div className='col-md-8'>
                <div className='card shadow-lg'>
                  <div className='card-body p-5'>
                    <form onSubmit={handleUpdate}>
                      <div className='mb-3'>
                        <label className='mb-2 text-muted' htmlFor='title'>
                          Title
                        </label>
                        <input
                          id='title'
                          autoFocus
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          type='text'
                          className='form-control'
                          name='title'
                          required
                        />
                        <div className='invalid-feedback'>Title is invalid</div>
                      </div>

                      <div className='mb-3'>
                        <label className='mb-2 text-muted' htmlFor='vol'>
                          Remaining Vol.
                        </label>
                        <input
                          id='vol'
                          value={vol}
                          onChange={(e) => setVol(e.target.value)}
                          type='number'
                          className='form-control'
                          name='vol'
                          required
                        />
                        <div className='invalid-feedback'>
                          Remaining Vol. is invalid
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label className='mb-2 text-muted' htmlFor='lastVol'>
                          Last Vol.
                        </label>
                        <input
                          id='lastVol'
                          value={lastVol}
                          onChange={(e) => setLastVol(e.target.value)}
                          type='number'
                          className='form-control'
                          name='lastVol'
                          required
                        />
                        <div className='invalid-feedback'>
                          Last Vol. is invalid
                        </div>
                      </div>

                      <div className='mb-3'>
                        <div className='mb-2 w-100'>
                          <label className='text-muted' htmlFor='status'>
                            Status
                          </label>
                        </div>
                        <div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='status'
                              id='active'
                              value={1}
                              checked={status === 1}
                              onChange={() => setStatus(1)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='active'
                            >
                              Active
                            </label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='status'
                              id='inactive'
                              value={2}
                              checked={status === 2}
                              onChange={() => setStatus(2)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='inactive'
                            >
                              Inactive
                            </label>
                          </div>
                        </div>
                        <div className='invalid-feedback'>
                          Status is required
                        </div>
                      </div>

                      <div className='text-center'>
                        <button
                          type='submit'
                          className='btn btn-outline-warning ms-auto mx-5'
                        >
                          Update
                        </button>
                        <button
                          type='button'
                          className='btn btn-outline-secondary ms-auto'
                          onClick={() =>
                            navigate('/admin/pre-order/books/list')
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AdminEditPreOrderBook
