import React from 'react'

const StockQtyTaken = ({
  productCode,
  productName,
  rack,
  box,
  stockAddData,
  handleStockQtyChange,
  handleStockQtySubmit,
  closeModal,
}) => {
  return (
    <>
      <div className='text-center h5 shadow-sm pb-3 bg-white rounded'>
        Stock Record
      </div>
      <form className='mt-3'>
        <div className='h6 mb-3'>
          <span className='me-2'>{productCode}</span> {productName}
        </div>

        <div className='form-group row mb-2'>
          <span className='col-sm-6 h6'>Rack No: {rack}</span>
          <span className='col-sm-6 h6'>Box No: {box}</span>
        </div>

        <div className='form-group row mb-2'>
          <label className='col-sm-4 h6 pt-3'>Quantity</label>
          <div className='col-sm-8'>
            <input
              type='number'
              name='quantity'
              className='form-control mt-2'
              value={stockAddData.quantity}
              onFocus={(e) => e.target.select()}
              onChange={handleStockQtyChange}
            />
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-4 h6 pt-3'>Remark</label>
          <div className='col-sm-8'>
            <input
              type='text'
              name='remark'
              className='form-control mt-2'
              value={stockAddData.remark}
              onFocus={(e) => e.target.select()}
              onChange={handleStockQtyChange}
            />
          </div>
        </div>

        <div className='text-end mt-4'>
          <button
            type='submit'
            class='btn btn-outline-success'
            onClick={handleStockQtySubmit}
          >
            <i class='bi bi-check-lg'></i> Submit
          </button>
          <button
            type='submit'
            class='btn btn-outline-danger ms-3'
            onClick={closeModal}
          >
            <i class='bi bi-x-lg'></i> Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default StockQtyTaken
