import React from 'react'
import Header from './Header'
import AdminHeader from './AdminHeader'
import LoginHeader from './LoginHeader'
import { useAuth } from '../../context/auth'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserHeader from './UserHeader'

const Layout = ({ children, title, description, keywords, author }) => {
  const [auth] = useAuth()

  const renderHeader = () => {
    if (auth?.user) {
      switch (auth.user.role) {
        case 1:
          return <AdminHeader />
        case 3:
          return <UserHeader />
        default:
          return <Header />
      }
    } else {
      return <LoginHeader />
    }
  }

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta name='author' content={author} />
        <title>{title}</title>
      </Helmet>
      {renderHeader()}
      <main style={{ marginTop: '50px' }}>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme='light'
        />
        {children}
      </main>
    </div>
  )
}

Layout.defaultProps = {
  title: 'POS System',
  description: 'Gospel Book Centre PLT',
  author: 'Gospel Book Centre PLT',
}

export default Layout
