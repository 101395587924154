import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Layout from '../../../../components/Layout/Layout'
import { saveAs } from 'file-saver'

const AdminErMismatchQty = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [mismatchData, setMismatchData] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')

  const observer = useRef()

  useEffect(() => {
    if (filterQuery.length > 2 || filterQuery === '') {
      setPage(1)
    }
  }, [filterQuery])

  useEffect(() => {
    const shouldFetch = filterQuery.length > 2 || filterQuery === ''
    if (shouldFetch) {
      fetchMismatchData(page, filterQuery)
    }
  }, [page, filterQuery])

  const fetchMismatchData = async (newPage = 1, search = '') => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check/get-ershop-data/mismatch/${id}`,
        {
          params: { page: newPage, limit: 30, search }, // Pass search parameter
        }
      )
      const { data, totalPages } = response.data
      if (newPage === 1) {
        setMismatchData(data)
      } else {
        setMismatchData((prev) => {
          const newData = data.filter(
            (item) => !prev.some((prevItem) => prevItem._id === item._id)
          )
          return [...prev, ...newData]
        })
      }
      setHasMore(newPage < totalPages)
    } catch (error) {
      toast.error('Failed to load mismatch data')
    } finally {
      setIsLoading(false)
    }
  }

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const handleFilterChange = (value) => {
    setFilterQuery(value)
  }

  const exportFile = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check/export-mismatch/${id}`,
        { responseType: 'blob' }
      )

      const contentDisposition = response.headers['content-disposition']
      let filename = 'download.xlsx' // Default filename if not specified
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1]
        }
      }

      saveAs(response.data, filename)
    } catch (error) {
      console.error('Failed to download the file:', error)
      toast.error('Failed to download the file.')
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <Layout title='ER Shop Mismatch Quantity'>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{ width: '100%', position: 'fixed', height: '70px' }}
      >
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex'>
            <input
              type='text'
              className='form-control'
              style={{ width: '250px' }}
              placeholder='Search Item Code or Desc'
              onChange={(e) => handleFilterChange(e.target.value)}
              value={filterQuery}
            />
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={() => setFilterQuery('')}
            >
              Clear
            </button>
          </div>

          <div className='fw-bold' style={{ fontSize: '18px' }}>
            ER Shop Quantity Mismatch / User Remarks
          </div>

          <button
            type='button'
            className='btn btn-outline-success btn-sm'
            onClick={() => exportFile()}
          >
            Export Data
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate(`/admin/stock-check/er-shop/${id}`)}
          >
            Back to ER Shop List
          </button>

          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            <i className='bi bi-arrow-up-circle'></i>
          </button>
        </div>
      </div>

      <div>
        {mismatchData.length === 0 && !isLoading && (
          <p>No mismatch data available.</p>
        )}
        <div className='table-responsive'>
          <table className='table' style={{ marginTop: '90px' }}>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Description</th>
                <th>Item Grp</th>
                <th>Item Type</th>
                <th className='text-center'>Qty</th>
                <th className='text-center'>Physical Qty</th>
                <th>User Remark</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {mismatchData.length === 0 && !isLoading ? (
                <tr>
                  <td colSpan='8' style={{ textAlign: 'center' }}>
                    No mismatch data available.
                  </td>
                </tr>
              ) : (
                mismatchData.map((item, index) => (
                  <tr key={item._id}>
                    <td>{item.itemCode}</td>
                    <td>{item.description}</td>
                    <td>{item.itemGrp}</td>
                    <td>{item.itemType}</td>
                    <td className='text-center'>{item.erShopQty}</td>
                    <td className='text-center'>{item.checkQty}</td>
                    <td>{item.remark || ''}</td>
                    <td>
                      {item.lastUptByCode && (
                        <>
                          {item.lastUptByCode}{' '}
                          {item.lastUptDate ? formatDate(item.lastUptDate) : ''}
                        </>
                      )}
                    </td>
                    {/* Attach the ref to the last item */}
                    {index === mismatchData.length - 1 && (
                      <td ref={lastElementRef}></td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {isLoading && <p>Loading more data...</p>}
      </div>
    </Layout>
  )
}

export default AdminErMismatchQty
