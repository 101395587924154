import React from 'react'
import { Modal, Button, Spin } from 'antd'
import { Link } from 'react-router-dom'

const UserBoxNoSelect = ({
  isOpen,
  selectedRack,
  boxList,
  onClose,
  id,
  loading,
}) => {
  return (
    <Modal
      title={`Rack: ${selectedRack}`}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key='close' onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800}
    >
      {loading ? (
        <div className='text-center'>
          <Spin size='large' />
        </div>
      ) : boxList.length > 0 ? (
        <div className='box-grid'>
          {boxList.map((box, index) => (
            <Link
              key={index}
              to={`/user/stock-check/store-room/${selectedRack}/${
                box.boxNo || 'empty'
              }/${id}`}
            >
              <Button
                key={index}
                className='box-button'
                style={{
                  fontSize: '16px',
                  margin: '10px',
                  textAlign: 'center',
                  height: '40px',
                  backgroundColor: box.boxStatus === 1 ? '#0d6efd' : 'initial', // Red if boxStatus is 1
                  color: box.boxStatus === 1 ? 'white' : 'black', // White text for better contrast
                }}
              >
                <span className=''>{box.boxNo || ''}</span>{' '}
              </Button>
            </Link>
          ))}
        </div>
      ) : (
        <div>No boxes found for this rack.</div>
      )}
    </Modal>
  )
}

export default UserBoxNoSelect
