import React, { useState, useEffect } from 'react'
import { Modal, Button, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const UserSameRackBoxNoSelect = ({
  id,
  rackno,
  boxno,
  isModalVisible,
  hideModal,
}) => {
  const [boxList, setBoxList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isModalVisible) {
      fetchBoxList()
    }
  }, [isModalVisible])

  const fetchBoxList = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/user/stock-check/get-boxes/${rackno}/${boxno}/${id}`
      )
      const boxes = response.data.boxes
      setBoxList(boxes)
    } catch (error) {
      console.error('Error fetching box list', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleBoxClick = (boxNo) => {
    hideModal()
    navigate(`/user/stock-check/store-room/${rackno}/${boxNo || 'empty'}/${id}`)
  }

  const hasValidBoxes = () => {
    return (
      boxList.length > 0 || (boxList.length === 1 && boxList[0].boxNo !== null)
    )
  }

  return (
    <Modal
      title={`Rack: ${rackno}`}
      open={isModalVisible}
      onCancel={hideModal}
      footer={[
        <Button key='close' onClick={hideModal}>
          Close
        </Button>,
      ]}
      width={800}
    >
      {isLoading ? (
        <div className='text-center'>
          <Spin size='large' />
        </div>
      ) : boxList.length > 0 ? (
        <div className='box-grid'>
          {boxList.map((box, index) => (
            <Button
              key={index}
              className='box-button'
              style={{
                fontSize: '16px',
                margin: '10px',
                textAlign: 'center',
                height: '40px',
                backgroundColor: box.boxStatus === 1 ? '#0d6efd' : 'initial', // Red if boxStatus is 1
                color: box.boxStatus === 1 ? 'white' : 'black', // White text for better contrast
              }}
              onClick={() => handleBoxClick(box.boxNo)}
            >
              <span>{box.boxNo || ''}</span>
            </Button>
          ))}
        </div>
      ) : (
        <div className='text-center'>
          <p>No other boxes available to select.</p>
        </div>
      )}
    </Modal>
  )
}

export default UserSameRackBoxNoSelect
