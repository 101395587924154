import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { Modal } from "antd";
import OrderForm from "../../components/Form/OrderForm";
import CashPaymentForm from "../../components/Form/CashPaymentForm";
import SearchProductForm from "../../components/Form/SearchProductForm";
import RefCodePaymentForm from "../../components/Form/RefCodePaymentForm";
import { useNavigate } from "react-router-dom";
import InitialCashForm from "../../components/Form/InitialCashForm";
import "../../styles/CashierStyle.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";

const CashierDashboard = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [updateQty, setUpdateQty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleSearchModal, setVisibleSearchModal] = useState(false);
  const [visibleCashFormModal, setVisibleCashFormModal] = useState(false);
  const [changeAmount, setChangeAmount] = useState("");
  const [selectItem, setSelectItem] = useState(null);
  const [searchvalue, setSearchvalue] = useState({ keyword: "" });
  const [searchItemNameCode, setSearchItemNameCode] = useState("");
  const [searchProducts, setSearchProducts] = useState([]);
  // eWallet
  const [visibleWalletFormModal, setVisibleWalletFormModal] = useState(false);
  // Debit/Credit Card
  const [visibleCardFormModal, setVisibleCardFormModal] = useState(false);
  const [paymentrefno, setPaymentrefno] = useState("");
  // Open & Close Counter
  const [openCounter, setOpenCounter] = useState(false);
  const [initialCash, setInitialCash] = useState("");
  const [visibleOpenCounterModal, setVisibleOpenCounterModal] = useState(false);
  const [closeCounter, setCloseCounter] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    getOrderId();
  }, []);

  //  Search Complete Order ID
  const getOrderId = async () => {
    try {
      const userId = auth?.user?._id;
      const { data } = await axios.post("/api/v1/order/get-order-id", {
        userId,
      });
      const getOrderNumber = data.slice(3);
      const converOrderNumber = (parseInt(getOrderNumber) + 1)
        .toString()
        .padStart(5, "0");
      data !== "0"
        ? setOrderId(auth?.user?.code + converOrderNumber)
        : setOrderId(auth?.user?.code + "00001");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOpenCounter();
  }, []);

  // Check if Open Counter
  const getOpenCounter = async () => {
    try {
      const userId = auth?.user?._id;
      const { data } = await axios.post("/api/v1/order/get-open-counter", {
        userId,
      });
      data !== null && data?.status !== 1
        ? setOpenCounter(true)
        : setOpenCounter(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `/api/v1/order/order-product/${searchvalue.keyword}`
      );

      const isFound = cart.some((element) => {
        if (element.itemCode === data.itemCode) {
          return true;
        }
        return false;
      });

      if (isFound) {
        let index = cart.findIndex((item) => item.itemCode === data.itemCode);
        let newQty = cart[index].mobileQty + 1;
        let updatedCart = {
          ...cart[index],
          mobileQty: newQty,
        };
        const newCarts = [...cart];
        newCarts[index] = updatedCart;
        setCart(newCarts);
        setTotalOrder(
          totalOrder + cart[index].price * (1 - cart[index].discount / 100)
        );
        setTotalQty(totalQty + cart[index].mobileQty);
      } else {
        data.mobileQty = 1;
        data.itemCode = data.itemCode;
        setCart([...cart, data]);
        setTotalOrder(totalOrder + data.price * (1 - data.discount / 100));
        setTotalQty(totalQty + data.mobileQty);
      }
      setSearchvalue({ keyword: "" });
    } catch (error) {
      console.log(error);
      setSearchvalue({ keyword: "" });
    }
  };

  const handlePayment = async (e, payment) => {
    e.preventDefault();
    try {
      const userId = auth?.user?._id;
      const { data } = await axios.post("/api/v1/order/order-product/payment", {
        cart,
        userId,
        orderId,
        totalOrder,
        payment,
        paymentrefno,
        status: "Complete",
      });
      setCart([]);
      setVisibleCashFormModal(false);
      setVisibleWalletFormModal(false);
      setVisibleCardFormModal(false);
      setChangeAmount("");
      setTotalOrder(0);
      setTotalQty(0);
      setPaymentrefno("");
      // setOrderId(parseInt(orderId) + 1);
      getOrderId();
      inputRef.current?.focus();
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Cart Item
  const removeCartItem = (cartItemCode) => {
    try {
      let storeCart = [...cart];
      let index = storeCart.findIndex((item) => item.itemCode === cartItemCode);
      storeCart.splice(index, 1);
      setCart(storeCart);
      setTotalOrder(
        totalOrder -
          cart[index].price *
            cart[index].mobileQty *
            (1 - cart[index].discount / 100)
      );
      setTotalQty(totalQty - cart[index].mobileQty);
    } catch (error) {
      console.log(error);
    }
  };

  // Update Qty
  const handleUpdateQty = async (e) => {
    e.preventDefault();
    try {
      let index = cart.findIndex(
        (item) => item.itemCode === selectItem.itemCode
      );

      let updatedCart = { ...cart[index], mobileQty: parseInt(updateQty) };
      const newCarts = [...cart];
      newCarts[index] = updatedCart;
      setCart(newCarts);
      setSelectItem(null);
      setUpdateQty(0);
      setVisibleModal(false);
      setTotalOrder(
        totalOrder -
          cart[index].price *
            cart[index].mobileQty *
            (1 - cart[index].discount / 100) +
          cart[index].price *
            parseInt(updateQty) *
            (1 - cart[index].discount / 100)
      );
      setTotalQty(totalQty - cart[index].mobileQty + parseInt(updateQty));
    } catch (error) {
      console.log(error);
    }
  };

  const closePaymentModal = (e) => {
    e.preventDefault();
    setVisibleCashFormModal(false);
    setVisibleWalletFormModal(false);
    setVisibleCardFormModal(false);
    setVisibleOpenCounterModal(false);
    setInitialCash("");
    setChangeAmount("");
  };

  // Search Item Name and Code
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/api/v1/order/search-item-info", {
        searchItemNameCode,
      });
      setSearchProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
    setSearchItemNameCode("");
  };

  // Add Search Product to Cart
  const selectProduct = (e, product) => {
    e.preventDefault();
    const isFound = cart.some((element) => {
      if (element.itemCode === product.itemCode) {
        return true;
      }
      return false;
    });

    if (isFound) {
      let index = cart.findIndex((item) => item.itemCode === product.itemCode);
      let newQty = cart[index].mobileQty + 1;
      let updatedCart = {
        ...cart[index],
        mobileQty: newQty,
      };

      const newCarts = [...cart];
      newCarts[index] = updatedCart;
      setCart(newCarts);
      setTotalOrder(
        totalOrder + cart[index].price * (1 - cart[index].discount / 100)
      );
      setTotalQty(totalQty + cart[index].mobileQty);
      setSearchProducts([]);
      setVisibleSearchModal(false);
    } else {
      product.mobileQty = 1;
      product.itemCode = product.itemCode;
      setCart([...cart, product]);
      setTotalOrder(totalOrder + product.price * (1 - product.discount / 100));
      setTotalQty(totalQty + product.mobileQty);

      setSearchProducts([]);
      setVisibleSearchModal(false);
    }
  };

  // Add Initial Cash
  const handleAddInitialCash = async (e) => {
    e.preventDefault();
    try {
      const userId = auth?.user?._id;
      const { data } = await axios.post("/api/v1/counter/initial-cash", {
        initialCash,
        userId,
      });
      setInitialCash("");
      setVisibleOpenCounterModal(false);
      setOpenCounter(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Dashboard"}>
      <div className="pt-3" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex mb-3 w-50">
            <form
              onSubmit={handleSearch}
              className="p-2"
              style={{ width: "60%" }}
            >
              <div>
                <div className="input-group">
                  <input
                    ref={inputRef}
                    type="text"
                    autoFocus
                    className="form-control"
                    placeholder="Scan or Key in Item Code and Press Enter"
                    readOnly={!openCounter}
                    value={searchvalue.keyword}
                    onChange={(e) =>
                      setSearchvalue({
                        ...searchvalue,
                        keyword: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </div>
              </div>
            </form>
            <div className="p-2">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!openCounter}
                onClick={() => {
                  setVisibleSearchModal(true);
                }}
              >
                <i class="bi bi-search"></i> Search Item
              </button>
            </div>
          </div>
          <div className="p-2 w-20 fs-5 fw-bold">Order ID: {orderId}</div>
          <div className="p-2 w-10 fs-5 fw-bold">Total Qty: {totalQty}</div>
          <div className="py-2 px-3 w-20 fs-4 fw-bold">
            Total: RM {(Math.round(totalOrder * 20) / 20).toFixed(2)}
          </div>
        </div>
      </div>
      <div className="w-100 px-2">
        <table className="table">
          <thead className="saleContain">
            <tr>
              <th style={{ width: "5%" }}>No.</th>
              <th scope="col">Item Code</th>
              <th style={{ width: "40%" }}>Description</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Unit Price</th>
              <th className="text-center">Discount</th>
              <th className="text-center">Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="saleContain">
            {cart?.map((c, index) => (
              <>
                <tr key={index}>
                  <td style={{ width: "5%" }}>{index + 1}</td>
                  <td>{c.itemCode}</td>
                  <td style={{ width: "40%" }}>{c.description}</td>
                  <td className="text-center">
                    {c.mobileQty}
                    <button
                      className="btn btn-outline-success btn-sm border-0 ms-2"
                      onClick={() => {
                        setVisibleModal(true);
                        setUpdateQty(c.mobileQty);
                        setSelectItem(c);
                      }}
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </td>
                  <td className="text-center">
                    {(Math.round(c.price * 20) / 20).toFixed(2)}
                  </td>
                  <td className="text-center">{c.discount}%</td>
                  <td className="text-center">
                    {(
                      Math.round(
                        c.price * c.mobileQty * (1 - c.discount / 100) * 20
                      ) / 20
                    ).toFixed(2)}
                  </td>
                  <td className="text-end">
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => removeCartItem(c.itemCode)}
                    >
                      <i class="bi bi-trash3"></i>
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="fixed-bottom row align-items-center"
        style={{ backgroundColor: "#f8f8f8", height: "80px" }}
      >
        <div className="col ms-4">
          <button
            className="btn btn-info"
            onClick={() => navigate("/cashier/transaction-records")}
          >
            Transaction Records
          </button>
          <button
            className={
              !openCounter
                ? "btn btn-warning mx-3"
                : "btn btn-outline-warning mx-3"
            }
            disabled={openCounter}
            onClick={() => setVisibleOpenCounterModal(true)}
          >
            Open Counter
          </button>
          <button
            className={
              !openCounter ? "btn btn-outline-warning" : "btn btn-warning"
            }
            disabled={!openCounter}
            onClick={() => navigate("/cashier/close-counter")}
          >
            Close Counter
          </button>
        </div>
        <div className="col">
          <button
            className={
              !cart || cart.length === 0
                ? "btn btn-outline-success ms-4 btn-lg"
                : "btn btn-success ms-4 btn-lg"
            }
            disabled={!cart || cart.length === 0}
            onClick={() => {
              setVisibleCashFormModal(true);
            }}
          >
            Cash
          </button>
          <button
            className={
              !cart || cart.length === 0
                ? "btn btn-outline-success ms-4 btn-lg"
                : "btn btn-success ms-4 btn-lg"
            }
            disabled={!cart || cart.length === 0}
            onClick={() => setVisibleWalletFormModal(true)}
          >
            eWallet
          </button>
          <button
            className={
              !cart || cart.length === 0
                ? "btn btn-outline-success ms-4 btn-lg"
                : "btn btn-success ms-4 btn-lg"
            }
            disabled={!cart || cart.length === 0}
            onClick={() => setVisibleCardFormModal(true)}
          >
            Card
          </button>
        </div>
      </div>

      <Modal
        onCancel={() => setVisibleSearchModal(false)}
        footer={null}
        open={visibleSearchModal}
        width={1000}
      >
        <SearchProductForm
          value={searchItemNameCode}
          setValue={setSearchItemNameCode}
          handleSubmit={handleSearchSubmit}
          products={searchProducts}
          selectProduct={selectProduct}
        />
      </Modal>

      <Modal
        onCancel={() => setVisibleModal(false)}
        footer={null}
        open={visibleModal}
      >
        <OrderForm
          value={updateQty}
          setValue={setUpdateQty}
          handleSubmit={handleUpdateQty}
        />
      </Modal>

      <Modal
        onCancel={() => setVisibleCashFormModal(false)}
        footer={null}
        open={visibleCashFormModal}
      >
        <CashPaymentForm
          value={totalOrder}
          changeValue={changeAmount}
          setChangeValue={setChangeAmount}
          handleSubmit={(e) => handlePayment(e, "Cash")}
          closeCashModal={closePaymentModal}
        />
      </Modal>

      <Modal
        onCancel={() => setVisibleWalletFormModal(false)}
        footer={null}
        open={visibleWalletFormModal}
      >
        <RefCodePaymentForm
          title="eWallet"
          value={totalOrder}
          refCodeValue={paymentrefno}
          setRefCodeValue={setPaymentrefno}
          handleSubmit={(e) => handlePayment(e, "eWallet")}
          closeCashModal={closePaymentModal}
        />
      </Modal>
      <Modal
        onCancel={() => setVisibleCardFormModal(false)}
        footer={null}
        open={visibleCardFormModal}
      >
        <RefCodePaymentForm
          title="Public Bank"
          value={totalOrder}
          refCodeValue={paymentrefno}
          setRefCodeValue={setPaymentrefno}
          handleSubmit={(e) => handlePayment(e, "Public Bank")}
          closeCashModal={closePaymentModal}
        />
      </Modal>
      <Modal
        onCancel={() => setVisibleOpenCounterModal(false)}
        footer={null}
        open={visibleOpenCounterModal}
      >
        <InitialCashForm
          handleSubmit={handleAddInitialCash}
          cashValue={initialCash}
          setCashValue={setInitialCash}
          closeCashModal={closePaymentModal}
        />
      </Modal>
    </Layout>
  );
};

export default CashierDashboard;
