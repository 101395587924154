import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import CreateNewEvent from './CreateNewEvent'
import { Modal } from 'antd'
import axios from 'axios'

const AdminPackingListIndex = () => {
  const navigate = useNavigate()
  const [events, setEvents] = useState([])
  const [visibleAddEventModal, setVisibleAddEventModal] = useState(false)
  const [stockAddData, setStockAddData] = useState({
    eventTitle: '',
  })

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get('/api/v1/admin/packing-list/events')
        setEvents(data)
      } catch (error) {
        console.log('Failed to fetch events:', error)
      }
    }

    fetchEvents()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setStockAddData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post(
        '/api/v1/admin/packing-list/event/add',
        { eventTitle: stockAddData.eventTitle }
      )
      setVisibleAddEventModal(false)
      setStockAddData({ eventTitle: '' })
      setEvents((prevEvents) => [...prevEvents, data.data]) // Add new event to the list
    } catch (error) {
      console.log(
        'Failed to add event:',
        error.response?.data.message || error.message
      )
    }
  }

  const handleViewDetails = (bookId) => {
    navigate(`/admin/packing-list/details/${bookId}`)
  }

  const groupItemDetails = (bookId) => {
    navigate('/admin/group-item')
  }

  const openModal = () => {
    setVisibleAddEventModal(true)
  }

  const closeModal = () => {
    setVisibleAddEventModal(false)
  }

  return (
    <Layout title={'Packing List'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-primary btn-sm'
            style={{ marginRight: '20px' }}
            onClick={() => groupItemDetails()}
          >
            Group Items
          </button>
          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            onClick={openModal}
          >
            Add New Packing List
          </button>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Event</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event._id}>
                <td>
                  {new Date(event.recordDate).toISOString().split('T')[0]}
                </td>
                <td>{event.event}</td>
                <td style={{ textAlign: 'right' }}>
                  <button
                    onClick={() => handleViewDetails(event._id)}
                    className='btn btn-outline-success btn-sm'
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        closable={false}
        onCancel={closeModal}
        footer={null}
        open={visibleAddEventModal}
      >
        <CreateNewEvent
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          eventTitle={stockAddData.eventTitle}
        />
      </Modal>
    </Layout>
  )
}

export default AdminPackingListIndex
