import React, { useState } from 'react'
import axios from 'axios'
import { useAuth } from '../../../context/auth'

const EditPreOrderRequestDate = ({ orderId, field, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState(
    value ? value.split('T')[0] : ''
  ) // format date to 'YYYY-MM-DD'
  const [auth] = useAuth()

  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = async () => {
    setIsEditing(false)
    await saveChanges(currentValue)
    onSave(currentValue)
  }

  const handleChange = (e) => {
    setCurrentValue(e.target.value)
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false)
      await saveChanges(currentValue)
      onSave(currentValue)
    }
  }

  const saveChanges = async (newValue) => {
    try {
      const token = auth?.token
      await axios.put(
        `/api/v1/admin/prebook/book-order/update/${orderId}`,
        { [field]: newValue },
        { headers: { Authorization: `Bearer ${token}` } }
      )
    } catch (error) {
      console.log('Error updating order:', error)
    }
  }

  return (
    <td onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input
          type='date'
          value={currentValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        currentValue
      )}
    </td>
  )
}

export default EditPreOrderRequestDate
