import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import BoxNoSelect from './BoxNoSelect'

const UserRackBoxSelect = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [rackBoxData, setRackBoxData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [boxList, setBoxList] = useState([])
  const [selectedRack, setSelectedRack] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loadingBoxList, setLoadingBoxList] = useState(false)

  const fetchRackBoxData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/user/stock-check/rack-box/${id}`
      )
      setRackBoxData(response.data)
    } catch (error) {
      setError('Failed to load Rackbox data')
      console.error('Error fetching rack box data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRackBoxData()
  }, [id])

  const groupRackNumbersByAlphabet = (rackData) => {
    const groupedData = rackData.reduce((acc, rack) => {
      const firstLetter = rack.rackNo.charAt(0).toUpperCase() // Extract rackNo and get first letter
      if (!acc[firstLetter]) {
        acc[firstLetter] = []
      }
      acc[firstLetter].push(rack) // Push the entire rack object
      return acc
    }, {})
    return groupedData
  }

  const handleRackClick = async (rackNo) => {
    setSelectedRack(rackNo)
    setLoadingBoxList(true)
    setIsModalOpen(true)

    try {
      const response = await axios.get(
        `/api/v1/user/stock-check/rack-box/${id}/boxes/${rackNo}`
      )
      const fetchedBoxList = response.data

      if (fetchedBoxList.length === 1) {
        const singleBoxNo = fetchedBoxList[0].boxNo || 'empty'
        navigate(`/user/stock-check/store-room/${rackNo}/${singleBoxNo}/${id}`)
      } else {
        setBoxList(fetchedBoxList)
      }
    } catch (error) {
      console.error('Failed to fetch box list:', error)
    } finally {
      setLoadingBoxList(false)
    }
  }
  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setBoxList([])
    setSelectedRack(null)
  }

  return (
    <Layout title='Rack Box Data'>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{
          width: '100%',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ height: '100%' }}
        >
          <div className='fw-bold'>Select Rack No.</div>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/user/stock-check')}
          >
            Return to Stock Check Dashboard
          </button>
        </div>
      </div>

      <div className='' style={{ paddingTop: '70px' }}>
        {loading ? (
          <div className='text-center'>
            <span>Loading...</span>
          </div>
        ) : error ? (
          <div className='text-center text-danger'>{error}</div>
        ) : (
          <div className='ps-5 pt-3 pb-5'>
            {rackBoxData && rackBoxData.length > 0 ? (
              <div className='d-flex flex-column'>
                {Object.entries(groupRackNumbersByAlphabet(rackBoxData)).map(
                  ([letter, racks]) => (
                    <div className='d-flex flex-wrap' key={letter}>
                      <span
                        className='fw-bold me-3 mt-2'
                        style={{ fontSize: '18px', width: '10px' }}
                      >
                        {letter}
                      </span>
                      {racks.map((rack) => (
                        <button
                          key={rack.rackNo}
                          className='btn btn-outline-success m-2'
                          style={{
                            width: '150px',
                            textAlign: 'center',
                            backgroundColor:
                              rack.rackStatus === 1 ? '#0d6efd' : 'initial', // Red if boxStatus is 1
                            color: rack.rackStatus === 1 ? 'white' : '#198754',
                          }}
                          onClick={() => handleRackClick(rack.rackNo)} // Fetch box numbers when clicked
                        >
                          {rack.rackNo}
                        </button>
                      ))}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div>No racks found.</div>
            )}
          </div>
        )}

        <BoxNoSelect
          isOpen={isModalOpen} // Use `isOpen` instead of `isVisible`
          selectedRack={selectedRack}
          id={id}
          boxList={boxList}
          loading={loadingBoxList}
          onClose={handleCloseModal}
        />
      </div>
    </Layout>
  )
}

export default UserRackBoxSelect
