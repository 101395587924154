import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, List } from 'antd'
import axios from 'axios'

const AdminNonAutoCountCategory = ({ visible, onClose }) => {
  const [categories, setCategories] = useState([])
  const [newCategory, setNewCategory] = useState('') // State for new category input
  const [loading, setLoading] = useState(false)

  // Fetch categories when the modal is visible
  useEffect(() => {
    if (visible) {
      fetchCategories()
    }
  }, [visible])

  // Fetch all categories from the API
  const fetchCategories = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        '/api/v1/admin/non-autocount/get-category'
      )
      setCategories(data)
    } catch (error) {
      console.error('Error fetching categories:', error)
    } finally {
      setLoading(false)
    }
  }

  // Add a new category
  const addCategory = async () => {
    if (!newCategory.trim()) return // Prevent empty category submission

    try {
      const { data } = await axios.post(
        '/api/v1/admin/non-autocount/add-new-category',
        { categoryName: newCategory }
      )
      setCategories((prev) => [...prev, data]) // Add the new category to the list
      setNewCategory('') // Clear input
    } catch (error) {
      console.error('Error adding category:', error)
    }
  }

  // Delete a category
  const deleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm('Confirm delete this category?')
    if (!confirmDelete) return

    try {
      await axios.delete(
        `/api/v1/admin/non-autocount/delete-category/${categoryId}`
      )
      setCategories((prev) =>
        prev.filter((category) => category._id !== categoryId)
      ) // Remove from UI
    } catch (error) {
      console.error('Error deleting category:', error)
    }
  }

  return (
    <Modal
      title='Category List'
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key='close' onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      {/* Input field to add new categories */}
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <Input
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder='Add new category'
          style={{ marginRight: '10px' }}
        />
        <Button
          type='primary'
          onClick={addCategory}
          disabled={!newCategory.trim()}
        >
          Add
        </Button>
      </div>

      {/* Display loading status */}
      {loading ? (
        <p>Loading categories...</p>
      ) : categories.length === 0 ? (
        <p>No categories available</p>
      ) : (
        <List
          bordered
          dataSource={categories}
          renderItem={(category) => (
            <List.Item
              actions={[
                <Button
                  type='link'
                  danger
                  onClick={() => deleteCategory(category._id)}
                >
                  <i className='bi bi-trash3-fill'></i>
                </Button>,
              ]}
            >
              {category.categoryName}
            </List.Item>
          )}
        />
      )}
    </Modal>
  )
}

export default AdminNonAutoCountCategory
