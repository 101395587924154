import React, { useEffect, useState } from 'react'
import axios from 'axios'

const MobileQtyLog = ({ itemId, itemDetails, lastUpdate }) => {
  const [logs, setLogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Fetch logs based on the itemId
  useEffect(() => {
    const fetchLogs = async () => {
      if (!itemId) return

      setIsLoading(true)
      setError(null) // Reset error state before fetching

      try {
        const response = await axios.get(
          `/api/v1/admin/stock-check-mobile/logs/${itemId}`
        )
        const { checkQtyLog } = response.data

        // Set the logs directly from the API response
        setLogs(checkQtyLog || [])
      } catch (error) {
        console.error('Failed to fetch logs', error)
        setError('Unable to fetch logs. Please try again later.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchLogs()
  }, [itemId, lastUpdate])

  // Helper function to format date
  function formatDate(dateString) {
    if (!dateString) return ''
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <>
      <div className='text-center h5 shadow-sm pb-3 bg-white rounded'>
        Qty Logs for {itemDetails?.itemCode || ''}{' '}
        {itemDetails?.description || ''}
      </div>

      <div className='table-responsive'>
        {isLoading ? (
          <div className='text-center'>Loading logs...</div>
        ) : error ? (
          <div className='text-center text-danger'>{error}</div>
        ) : logs.length === 0 ? (
          <div className='text-center'>No logs available for this item.</div>
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Qty</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{formatDate(log?.checkDateTime)}</td>
                  <td>{log.user.code}</td>
                  <td>{log.qty}</td>
                  <td>{log.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}

export default MobileQtyLog
