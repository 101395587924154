import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../context/auth'
import { Modal, Button } from 'antd'
import QtyLog from '../../Admin/PackingList/QtyLog'
import UserPackingListTable from './PackingListTable'

const UserPackingListDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [searchData, setSearchData] = useState('')
  const [packingDataList, setPackingDataList] = useState([])
  const [editMode, setEditMode] = useState(null)
  const [editField, setEditField] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(null)
  const [currentItemDetails, setCurrentItemDetails] = useState({})
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const [originalValues, setOriginalValues] = useState({})
  const barcodeInputRef = useRef(null)
  const [focusBarcodeInput, setFocusBarcodeInput] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [auth] = useAuth()
  const userId = auth?.user?._id

  useEffect(() => {
    const shouldFetch = filterQuery.length >= 3 || filterQuery === ''
    if (shouldFetch) {
      fetchData(page)
    }
  }, [page, filterQuery])

  useEffect(() => {
    if (filterQuery.length >= 3 || filterQuery === '') {
      setPage(1)
    }
  }, [filterQuery])

  useEffect(() => {
    barcodeInputRef.current?.focus()
    setFocusBarcodeInput(false)
  }, [focusBarcodeInput])

  const fetchData = async (newPage = 1) => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/user/packing-list/details/${id}`,
        {
          params: {
            userId,
            page: newPage,
            limit: 30,
            search: filterQuery,
          },
        }
      )

      if (newPage === 1) {
        setPackingDataList(response.data.data)
      } else {
        setPackingDataList((prev) => [...prev, ...response.data.data])
      }
      setHasMore(response.data.page < response.data.totalPages)
    } catch (error) {
      console.error('Failed to fetch packing list data:', error)
      setPackingDataList([])
    } finally {
      setIsLoading(false)
    }
  }

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const toggleEdit = (itemId, field, currentValue) => {
    if (editMode === itemId && editField === field) {
      setEditMode(null)
      setEditField(null)

      setPackingDataList((currentList) =>
        currentList.map((item) =>
          item._id === itemId
            ? {
                ...item,
                [field]: originalValues[`${itemId}-${field}`] || item[field],
              }
            : item
        )
      )
    } else {
      setEditMode(itemId)
      setEditField(field)
      setOriginalValues((prev) => ({
        ...prev,
        [`${itemId}-${field}`]: currentValue,
      }))
    }
  }

  const handleQtyChange = (itemId, value, type) => {
    setPackingDataList((currentList) =>
      currentList.map((item) =>
        item._id === itemId ? { ...item, [type]: value } : item
      )
    )
  }

  const saveQty = async (itemId, type) => {
    const item = packingDataList.find((item) => item._id === itemId)
    if (!item) return

    const originalValue = Number(
      String(originalValues[`${itemId}-${type}`]).trim()
    )
    const updatedValue = Number(String(item[type]).trim())

    if (originalValue === updatedValue) {
      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
      return
    }
    try {
      await axios.patch(`/api/v1/user/packing-list/update-item/${itemId}`, {
        [type]: updatedValue,
        userId,
      })
      setEditMode(null)
      setEditField(null)
      fetchData()
      setLastUpdate(new Date())
      setFocusBarcodeInput(true)
    } catch (error) {
      console.error(`Failed to update ${type}:`, error)
      toast.error(`Failed to update ${type}.`)
    }
  }

  const scanResult = async () => {
    try {
      const { data } = await axios.post(
        '/api/v1/user/packing-list/scan-stock',
        { searchData, id, userId }
      )
      if (data.products.length === 0) {
        toast.info('No records found')
      } else {
        fetchData()
        setLastUpdate(new Date())
      }
    } catch (error) {
      toast.error('Item not found in the list!')
    } finally {
      clearInput()
    }
  }

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && searchData.trim()) {
      await scanResult()
    }
  }

  const handleQtyKeyPress = (event, itemId, type) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      saveQty(itemId, type)
    }
  }

  const clearInput = () => {
    setSearchData('')
  }

  const getSearchContent = (value) => {
    setSearchData(value)
  }

  const handleFilterChange = (value) => {
    setFilterQuery(value)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setVisibleLogModal(false)
  }

  const openLogModal = (itemId) => {
    const item = packingDataList.find((item) => item._id === itemId)
    if (item) {
      setCurrentItemId(itemId)
      setCurrentItemDetails({
        itemCode: item.itemCode,
        description: item.description,
      })
      setVisibleLogModal(true)
    }
  }

  return (
    <Layout title={'Packing List'}>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{ width: '100%', position: 'fixed', height: '120px' }}
      >
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex'>
            <input
              type='text'
              className='form-control'
              style={{ width: '300px' }}
              placeholder='Search Item Code or Desc'
              onChange={(e) => handleFilterChange(e.target.value)}
              value={filterQuery}
            />
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={() => setFilterQuery('')}
            >
              Clear
            </button>
          </div>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/user/packing-list')}
          >
            Back
          </button>
        </div>
        <div className='col-auto' style={{ display: 'flex' }}>
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Scan / Enter Item Code or Barcode for Return'
            onChange={(e) => getSearchContent(e.target.value)}
            onKeyDown={handleKeyPress}
            style={{ flex: 1 }}
            ref={barcodeInputRef}
            autoFocus
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={clearInput}
            style={{ marginLeft: '0px' }}
          >
            Clear
          </button>
          <button
            type='button'
            className='btn btn-outline-info btn-sm ms-5'
            onClick={() => fetchData()}
          >
            <i class='bi bi-arrow-clockwise'></i>
          </button>
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <UserPackingListTable
            packingDataList={packingDataList}
            lastElementRef={lastElementRef}
            handleEdit={toggleEdit} // example of passing a function
            editMode={editMode}
            editField={editField}
            handleQtyChange={handleQtyChange}
            saveQty={saveQty}
            handleQtyKeyPress={handleQtyKeyPress}
            toggleEdit={toggleEdit}
            openLogModal={openLogModal}
          />
        </div>
      </div>

      <Modal
        closable={false}
        onCancel={() => setVisibleLogModal(false)}
        open={visibleLogModal}
        width={'70%'}
        footer={[
          <Button key='cancel' onClick={() => setVisibleLogModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <QtyLog
          closeLogModal={closeModal}
          itemId={currentItemId}
          itemDetails={currentItemDetails}
          lastUpdate={lastUpdate}
        />
      </Modal>
    </Layout>
  )
}

export default UserPackingListDetails
