import React, { useState } from 'react'

const EditPreOrderRequest = ({ orderId, field, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)

  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = async () => {
    setIsEditing(false)
    if (currentValue !== value) {
      onSave(currentValue)
    }
  }

  const handleChange = (e) => {
    setCurrentValue(e.target.value)
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false)
      if (currentValue !== value) {
        onSave(currentValue)
      }
    }
  }

  return (
    <td onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input
          type='text'
          value={currentValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        currentValue || (
          <i
            className='bi bi-pencil-fill'
            style={{
              cursor: 'pointer',
              color: 'grey',
              fontSize: '12px',
            }}
            onClick={handleDoubleClick}
          ></i>
        )
      )}
    </td>
  )
}

export default EditPreOrderRequest
