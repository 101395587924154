import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'

const AdminEditUser = () => {
  const params = useParams()
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState(0)
  const [status, setStatus] = useState(0)
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  // Get Single Product
  const getUserInfo = async () => {
    try {
      const { data } = await axios.get(`/api/v1/user/user-details/${params.id}`)
      setName(data.getUser.name)
      setUsername(data.getUser.username)
      setCode(data.getUser.code)
      setRole(data.getUser.role)
      setStatus(data.getUser.status)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.put(
        `/api/v1/user/update-user-details/${params.id}`,
        {
          name,
          username,
          code,
          role,
          status,
          password,
        }
      )

      if (data?.success) {
        navigate('/admin/users')
      } else {
        console.log(data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeStatus = (e) => {
    setStatus(parseInt(e.target.value, 10)) // Convert to integer
  }
  const handleChangeRole = (e) => {
    setRole(parseInt(e.target.value, 10)) // Convert to integer
  }

  useEffect(() => {
    getUserInfo()
    //eslint-disable-next-line
  }, [])

  return (
    <Layout title={'Create New User'}>
      <section className='h-100'>
        <div className='container h-100'>
          <div className='row justify-content-sm-center h-100'>
            <div className='col-md-6'>
              <div className='text-center my-4'>
                <h1 className='fs-4 card-title fw-bold'>Edit User</h1>
              </div>
              <div className='card shadow-lg'>
                <div className='card-body p-5'>
                  <form onSubmit={handleUpdate}>
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='name'>
                        Name
                      </label>
                      <input
                        id='name'
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type='text'
                        className='form-control'
                        name='name'
                        required
                      />
                      <div className='invalid-feedback'>Name is invalid</div>
                    </div>
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='username'>
                        Username
                      </label>
                      <input
                        id='username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type='text'
                        className='form-control'
                        name='username'
                        required
                      />
                      <div className='invalid-feedback'>
                        Username is invalid
                      </div>
                    </div>
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='username'>
                        Code
                      </label>
                      <input
                        id='code'
                        value={code}
                        onChange={(e) => setCode(e.target.value.toUpperCase())}
                        type='text'
                        className='form-control'
                        name='code'
                        required
                      />
                      <div className='invalid-feedback'>Code is invalid</div>
                    </div>

                    <div className='mb-3'>
                      <div className='mb-2 w-100'>
                        <label className='text-muted' htmlFor=''>
                          Role
                        </label>
                      </div>
                      <div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='role'
                            id='admin1'
                            value={1}
                            checked={role === 1}
                            onChange={handleChangeRole}
                          />
                          <label className='form-check-label' htmlFor='admin1'>
                            Admin
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='role'
                            id='user3'
                            value={3}
                            checked={role === 3}
                            onChange={handleChangeRole}
                          />
                          <label className='form-check-label' htmlFor='user3'>
                            User
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='role'
                            id='cashier2'
                            value={2}
                            checked={role === 2}
                            onChange={handleChangeRole}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='cashier2'
                          >
                            Cashier
                          </label>
                        </div>
                      </div>
                      <div className='invalid-feedback'>Role is required</div>
                    </div>
                    <div className='mb-3'>
                      <div className='mb-2 w-100'>
                        <label className='text-muted' htmlFor='password'>
                          Status
                        </label>
                      </div>
                      <div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='status'
                            id='active'
                            value={1}
                            checked={status === 1}
                            onChange={handleChangeStatus}
                          />
                          <label className='form-check-label' htmlFor='active'>
                            Active
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='status'
                            id='inactive'
                            value={2}
                            checked={status === 2}
                            onChange={handleChangeStatus}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='inactive'
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                      <div className='invalid-feedback'>Status is required</div>
                    </div>
                    <div className='mb-3'>
                      <div className='mb-2 w-100'>
                        <label className='text-muted' htmlFor='password'>
                          Password
                        </label>
                      </div>
                      <input
                        id='password'
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='form-control'
                        name='password'
                      />
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-outline-primary ms-auto mx-5'
                      >
                        Update
                      </button>
                      <button
                        className='btn btn btn-outline-secondary ms-auto'
                        onClick={() => navigate('/admin/users')}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AdminEditUser
