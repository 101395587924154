import React, { useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../../context/auth'

const AdminCreatePreOrderBook = () => {
  const [title, setTitle] = useState('')
  const [vol, setVol] = useState('')
  const [lastVol, setLastVol] = useState('')
  const [status, setStatus] = useState(1)
  const [auth] = useAuth()

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const token = auth?.token
      const { data } = await axios.post(
        '/api/v1/admin/prebook/new',
        {
          title,
          vol,
          lastVol,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (data?.success) {
        navigate('/admin/pre-order/books/list')
      } else {
        console.log(data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout title={'Create New Pre-order Book'}>
      <section className='h-100'>
        <div className='container h-100'>
          <div className='row justify-content-sm-center h-100'>
            <div className='col-md-8'>
              <div className='text-center my-4'>
                <h1 className='fs-4 card-title fw-bold'>Add New Pre Book</h1>
              </div>
              <div className='card shadow-lg'>
                <div className='card-body p-5'>
                  <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='title'>
                        Title
                      </label>
                      <input
                        id='title'
                        autoFocus
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        type='text'
                        className='form-control'
                        name='title'
                        required
                      />
                      <div className='invalid-feedback'>Title is invalid</div>
                    </div>

                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='vol'>
                        Remaining Vol.
                      </label>
                      <input
                        id='vol'
                        value={vol}
                        onChange={(e) => setVol(e.target.value)}
                        type='number'
                        className='form-control'
                        name='vol'
                        required
                      />
                      <div className='invalid-feedback'>
                        Remaining Vol. is invalid
                      </div>
                    </div>
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='lastVol'>
                        Last Vol.
                      </label>
                      <input
                        id='lastVol'
                        value={lastVol}
                        onChange={(e) => setLastVol(e.target.value)}
                        type='number'
                        className='form-control'
                        name='lastVol'
                        required
                      />
                      <div className='invalid-feedback'>
                        Last Vol. is invalid
                      </div>
                    </div>

                    <div className='mb-3'>
                      <div className='mb-2 w-100'>
                        <label className='text-muted' htmlFor='status'>
                          Status
                        </label>
                      </div>
                      <div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='status'
                            id='active'
                            value={1}
                            onChange={(e) => setStatus(e.target.value)}
                          />
                          <label className='form-check-label' htmlFor='active'>
                            Active
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='status'
                            id='inactive'
                            value={2}
                            onChange={(e) => setStatus(e.target.value)}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='inactive'
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                      <div className='invalid-feedback'>Status is required</div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-outline-primary ms-auto mx-5'
                      >
                        Submit
                      </button>
                      <button
                        className='btn btn btn-outline-secondary ms-auto'
                        onClick={() => navigate('/admin/pre-order/books/list')}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AdminCreatePreOrderBook
