import React, { useState } from 'react'
import axios from 'axios'

const AdminEditLocationSelectData = ({ value, onSave, currentLocationId }) => {
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(currentLocationId)
  const [showLocationDropdown, setShowLocationDropdown] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [locationName, setLocationName] = useState(value)

  const fetchLocations = async () => {
    try {
      const { data } = await axios.get(
        '/api/v1/admin/non-autocount/get-location'
      )
      setLocations(data)
    } catch (error) {
      console.error('Error fetching locations:', error)
    }
  }

  const handleSelectLocation = async (selectedLocation) => {
    setSelectedLocation(selectedLocation._id)
    setLocationName(selectedLocation.locationName)
    setShowLocationDropdown(false)
    setIsEditing(false)
    await onSave(selectedLocation)
  }

  const handleEditClick = async () => {
    setIsEditing(true)
    await fetchLocations()
    setShowLocationDropdown(true)
  }

  return (
    <td>
      {isEditing ? (
        <div style={{ position: 'relative' }}>
          <select
            value={selectedLocation}
            onChange={(e) => {
              const selectedLoc = locations.find(
                (loc) => loc._id === e.target.value
              )
              handleSelectLocation(selectedLoc)
            }}
            onBlur={() => setIsEditing(false)}
            autoFocus
            style={{ width: '100%' }}
          >
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.locationName}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <span onClick={handleEditClick} style={{ cursor: 'pointer' }}>
          {locationName || (
            <i
              className='bi bi-pencil-fill'
              style={{
                cursor: 'pointer',
                color: 'grey',
                fontSize: '12px',
              }}
            ></i>
          )}
        </span>
      )}
    </td>
  )
}

export default AdminEditLocationSelectData
