import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../context/auth'
import axios from 'axios'
import EditPreOrderRequestDate from './EditPreOrderRequestDate'
import EditPreOrderRequest from './EditPreOrderRequest'

const UserPreOrderBookRequest = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [orders, setOrders] = useState([])
  const [bookTitle, setBookTitle] = useState('')
  const [vol, setVol] = useState(0)
  const [lastVol, setLastVol] = useState(0)
  const [auth] = useAuth()
  const [visibleRows, setVisibleRows] = useState({})

  const getAllBookOrderList = async () => {
    try {
      const token = auth?.token
      const { data } = await axios.get(
        `/api/v1/user/pre-order/book-order/details/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (data?.success) {
        setOrders(data.orders)
        setBookTitle(data.bookTitle)
        setVol(data.vol)
        setLastVol(data.lastVol)
        initializeVisibility(data.orders)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const initializeVisibility = (orders) => {
    const visibility = orders.reduce(
      (acc, order) => ({
        ...acc,
        [order._id]: false,
      }),
      {}
    )
    setVisibleRows(visibility)
  }

  useEffect(() => {
    getAllBookOrderList()
  }, [id])

  const handleSave = async (index, field, value) => {
    try {
      const orderId = orders[index]._id
      const userId = auth.user._id
      const token = auth?.token

      const { data } = await axios.put(
        `/api/v1/user/pre-order/book-order/update/${orderId}/${userId}`,
        { [field]: value },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (data.success) {
        // Update the local state with the updated order from the server
        const updatedOrders = [...orders]
        updatedOrders[index] = {
          ...updatedOrders[index],
          ...data.order,
          lastUptBy: { code: auth.user.code },
        }
        setOrders(updatedOrders)
      } else {
        console.error('Failed to update the order')
      }
    } catch (error) {
      console.log('Error updating order:', error)
    }
  }

  const toggleVisibility = (orderId) => {
    setVisibleRows((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }))
  }

  const volumeHeaders = []
  for (let i = 0; i < vol; i++) {
    volumeHeaders.push(<th key={i}>{lastVol - vol + 1 + i}</th>)
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <Layout title={`Order Details for ${bookTitle}`}>
      <div className='pt-3 mb-4 mx-3'>
        <div className='text-end'>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/user/pre-order/books/list')}
          >
            Back
          </button>
        </div>
        <div
          className='text-center fw-bold fs-5 mb-2'
          style={{ color: '#2f4483' }}
        >
          {bookTitle}
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th rowSpan='2'>Date</th>
              <th rowSpan='2'>Name</th>
              <th rowSpan='2'>Locality</th>
              <th rowSpan='2'>Phone</th>
              <th rowSpan='2'>Qty</th>
              <th rowSpan='2'>Remark</th>
              <th className='text-center' colSpan={vol}>
                Books
              </th>
              <th rowSpan='2'>Last Updated</th>
              <th rowSpan='2'></th>
            </tr>
            <tr>
              {Array.from({ length: vol }, (_, i) => (
                <th key={i}>{lastVol - vol + 1 + i}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <>
                <tr key={order._id}>
                  <EditPreOrderRequestDate
                    orderId={order._id}
                    field='orderdate'
                    value={order.orderdate}
                    onSave={(value) => handleSave(index, 'orderdate', value)}
                  />
                  <EditPreOrderRequest
                    orderId={order._id}
                    field='name'
                    value={order.name}
                    onSave={(value) => handleSave(index, 'name', value)}
                  />
                  <EditPreOrderRequest
                    orderId={order._id}
                    field='locality'
                    value={order.locality}
                    onSave={(value) => handleSave(index, 'locality', value)}
                  />
                  <EditPreOrderRequest
                    orderId={order._id}
                    field='phone'
                    value={order.phone}
                    onSave={(value) => handleSave(index, 'phone', value)}
                  />
                  <EditPreOrderRequest
                    orderId={order._id}
                    field='orderQty'
                    value={order.orderQty}
                    onSave={(value) => handleSave(index, 'orderQty', value)}
                  />
                  <EditPreOrderRequest
                    orderId={order._id}
                    field='remark'
                    value={order.remark}
                    onSave={(value) => handleSave(index, 'remark', value)}
                  />

                  {Array.from({ length: vol }, (_, i) => (
                    <EditPreOrderRequest
                      key={i}
                      orderId={order._id}
                      field={`volumes.volume${lastVol - vol + 1 + i}`}
                      value={
                        order.volumes
                          ? order.volumes[`volume${lastVol - vol + 1 + i}`]
                          : ''
                      }
                      onSave={(value) =>
                        handleSave(
                          index,
                          `volumes.volume${lastVol - vol + 1 + i}`,
                          value
                        )
                      }
                    />
                  ))}
                  <td>
                    {order.lastUptBy && order.lastUptBy.code}{' '}
                    {order.updatedAt ? formatDate(order.updatedAt) : ''}
                  </td>
                  <td className='text-end'>
                    <span
                      className='ms-3'
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleVisibility(order._id)}
                    >
                      <i
                        className='bi-info-circle-fill mx-3'
                        style={{ color: '#00a6ff' }}
                      ></i>
                    </span>
                  </td>
                </tr>
                {visibleRows[order._id] && (
                  <tr>
                    <td colSpan={volumeHeaders.length + 8}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <span className='fw-bold me-3'>Email:</span>
                          <EditPreOrderRequest
                            orderId={order._id}
                            field='email'
                            value={order.email}
                            onSave={(value) =>
                              handleSave(index, 'email', value)
                            }
                          />
                        </div>
                        <div style={{ display: 'flex' }}>
                          <span className='fw-bold me-3'>Address:</span>
                          <EditPreOrderRequest
                            orderId={order._id}
                            field='address'
                            value={order.address}
                            onSave={(value) =>
                              handleSave(index, 'address', value)
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default UserPreOrderBookRequest
