import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const AdminGroupItemDetails = () => {
  const navigate = useNavigate()
  const [groupItems, setGroupItems] = useState([])

  useEffect(() => {
    const fetchGroupItems = async () => {
      try {
        const { data } = await axios.get('/api/v1/admin/group-item/list')
        setGroupItems(data)
      } catch (error) {
        console.error('Error fetching group items:', error)
      }
    }

    fetchGroupItems()
  }, [])

  const handleCreate = () => {
    navigate(`/admin/group-item/create`)
  }

  const handleEdit = (id) => {
    navigate(`/admin/group-item/edit/${id}`)
  }

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm('Confirm delete group item?')
    if (isConfirmed) {
      try {
        await axios.delete(`/api/v1/admin/group-item/${id}`)
        setGroupItems(groupItems.filter((item) => item._id !== id))
      } catch (error) {
        console.error('Error deleting group item:', error)
      }
    }
  }

  return (
    <Layout title='Group Item'>
      <div style={{ padding: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-sm'
            style={{ marginRight: '20px' }}
            onClick={() => handleCreate()}
          >
            Create Group Items
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/admin/packing-list')}
          >
            Back
          </button>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th>Group Item Code</th>
              <th>Group Desc</th>
              <th>Items</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {groupItems.map((groupItem) => (
              <tr key={groupItem._id}>
                <td>{groupItem.grpItemCode}</td>
                <td>{groupItem.grpDesc}</td>
                <td>
                  {groupItem.grpItems.map((item, index) => (
                    <div key={index}>
                      {item.itemCode} - {item.description}
                    </div>
                  ))}
                </td>
                <td className='text-end'>
                  <button
                    type='button'
                    className='btn btn-outline-warning btn-sm'
                    style={{ marginRight: '10px' }}
                    onClick={() => handleEdit(groupItem._id)}
                  >
                    Edit
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-danger btn-sm'
                    onClick={() => handleDelete(groupItem._id)}
                  >
                    Del.
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default AdminGroupItemDetails
