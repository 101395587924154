import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const LoginHeader = () => {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      variant='dark'
      fixed='top'
      style={{ backgroundColor: 'rgb(47, 68, 131)' }}
    >
      <Container>
        <Navbar.Brand as={Link} to='/login'>
          WMS
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='ms-auto'>
            <Nav.Link as={Link} to='/login' style={{ color: 'white' }}>
              Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default LoginHeader
