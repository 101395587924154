import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const AdminGroupItemCreate = () => {
  const navigate = useNavigate()
  const [groupItemCode, setGroupItemCode] = useState('')
  const [groupDesc, setGroupDesc] = useState('')
  const [searchItem, setSearchItem] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const handleSearch = async () => {
    if (searchItem.length > 3) {
      try {
        const response = await axios.get(`/api/v1/admin/group-item/search`, {
          params: { query: searchItem },
        })
        setSearchResults(response.data)
      } catch (error) {
        console.error('Error searching items:', error)
      }
    } else {
      setSearchResults([])
    }
  }

  useEffect(() => {
    if (searchItem && searchItem.length > 3) {
      const delayDebounce = setTimeout(() => {
        handleSearch()
      }, 300)

      return () => clearTimeout(delayDebounce)
    } else {
      setSearchResults([])
    }
  }, [searchItem])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await axios.post('/api/v1/admin/group-item/create', {
        grpItemCode: groupItemCode,
        grpDesc: groupDesc,
        grpItems: selectedItems.map((item) => ({
          itemCode: item.itemCode,
          description: item.description,
          itemGrp: item.itemGrp,
          price: item.price,
        })),
      })

      setGroupItemCode('')
      setGroupDesc('')
      setSearchItem('')
      setSelectedItems([])
      setSearchResults([])
    } catch (error) {
      console.error('Error submitting group item list:', error)
    }
  }

  const handleSelectItem = (item) => {
    setSelectedItems((prevItems) => {
      if (!prevItems.find((x) => x._id === item._id)) {
        return [...prevItems, item]
      }
      return prevItems
    })
  }

  const handleDeleteItem = (itemId) => {
    setSelectedItems(selectedItems.filter((item) => item._id !== itemId))
  }

  const handleClear = () => {
    setGroupItemCode('')
    setGroupDesc('')
    setSearchItem('')
    setSearchResults([])
  }

  return (
    <Layout title='Group Item Create'>
      <div style={{ padding: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <h5 style={{ margin: 0 }}>Create Group Items</h5>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/admin/group-item')}
          >
            Back
          </button>
        </div>

        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.target.type !== 'textarea') {
              e.preventDefault()
            }
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>
              Group Item Code
            </label>
            <input
              type='text'
              className='form-control'
              style={{ width: '100%', padding: '8px' }}
              value={groupItemCode}
              onChange={(e) => setGroupItemCode(e.target.value)}
              placeholder='Enter group item code'
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>
              Group Description
            </label>
            <input
              type='text'
              className='form-control'
              style={{ width: '100%', padding: '8px' }}
              value={groupDesc}
              onChange={(e) => setGroupDesc(e.target.value)}
              placeholder='Enter group description'
            />
          </div>

          <div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '10px',
                fontWeight: 'bold',
              }}
            >
              Selected Items:
            </div>
            <ul style={{ paddingLeft: '10px' }}>
              {selectedItems.map((item) => (
                <li
                  key={item._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '5px',
                  }}
                >
                  {item.itemCode} - {item.description}
                  <button
                    type='button'
                    className='btn btn-danger btn-sm'
                    onClick={() => handleDeleteItem(item._id)}
                  >
                    <i className='bi bi-trash3' style={{ color: '#fff' }}></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>
              Search Item
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type='text'
                className='form-control'
                style={{ width: '100%', padding: '8px' }}
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
                placeholder='Search item'
              />
              <button
                type='button'
                className='btn btn-secondary'
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>

          <table className='table'>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((item) => (
                <tr key={item._id}>
                  <td>{item.itemCode}</td>
                  <td>{item.description}</td>
                  <td>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      onClick={() => handleSelectItem(item)}
                    >
                      Select
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '30px',
            }}
          >
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default AdminGroupItemCreate
