import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Modal } from 'antd'
import { toast } from 'react-toastify'
import CreateNewStockCheck from './CreateNewStockCheck'

const AdminStockCheckListIndex = () => {
  const navigate = useNavigate()
  const [events, setEvents] = useState([])
  const [visibleAddEventModal, setVisibleAddEventModal] = useState(false)
  const [stockAddData, setStockAddData] = useState({
    eventTitle: '',
  })

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get('/api/v1/admin/stock-check/lists')
        setEvents(data)
      } catch (error) {
        console.log('Failed to fetch lists:', error)
      }
    }

    fetchEvents()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setStockAddData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post('/api/v1/admin/stock-check/list/add', {
        eventTitle: stockAddData.eventTitle,
      })
      setVisibleAddEventModal(false)
      setStockAddData({ eventTitle: '' })
      setEvents((prevEvents) => [...prevEvents, data.data]) // Add new event to the list
    } catch (error) {
      console.log(
        'Failed to add event:',
        error.response?.data.message || error.message
      )
    }
  }

  const handleViewDetails = (listId) => {
    navigate(`/admin/stock-check/details/${listId}`, {
      state: { id: listId },
    })
  }

  const openModal = () => {
    setVisibleAddEventModal(true)
  }

  const closeModal = () => {
    setVisibleAddEventModal(false)
  }

  const updateEventStatus = (eventId, newStatus) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event._id === eventId ? { ...event, status: newStatus } : event
      )
    )
  }

  const handleStatusClick = async (eventId, currentStatus) => {
    const confirmed = window.confirm('Confirm update?')
    if (confirmed) {
      try {
        const newStatus = currentStatus === 1 ? 2 : 1
        const response = await axios.patch(
          `/api/v1/admin/stock-check/update-status/${eventId}`,
          { status: newStatus }
        )

        if (response.status === 200) {
          toast.success('Status updated successfully')
          updateEventStatus(eventId, newStatus)
        }
      } catch (error) {
        toast.error('Failed to update status')
      }
    }
  }

  return (
    <Layout title={'Stock Check List'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            onClick={openModal}
          >
            Add New Stock Check
          </button>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Info</th>
              <th scope='col'>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event._id}>
                <td>
                  {new Date(event.recordDate).toISOString().split('T')[0]}
                </td>
                <td>{event.event}</td>
                <td
                  style={{
                    color: event.status === 1 ? 'green' : 'grey',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleStatusClick(event._id, event.status)}
                >
                  {event.status === 1 ? 'Open' : 'Closed'}
                </td>
                <td style={{ textAlign: 'right' }}>
                  <button
                    className='btn btn-outline-success btn-sm'
                    onClick={() => handleViewDetails(event._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        closable={false}
        onCancel={closeModal}
        footer={null}
        open={visibleAddEventModal}
      >
        <CreateNewStockCheck
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          eventTitle={stockAddData.eventTitle}
        />
      </Modal>
    </Layout>
  )
}

export default AdminStockCheckListIndex
