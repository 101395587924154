import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import useInfiniteScroll from './useInfiniteScroll'
import axios from 'axios'
import '../../../styles/AdminStyle.css'

const AdminCompareStock = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [totalItem, setTotalItem] = useState(0)
  const [page, setPage] = useState(0)
  const [HasMore, setHasMore] = useState(true)
  const [lastElementRef] = useInfiniteScroll(
    HasMore ? loadMoreItems : () => {},
    isFetching
  )

  useEffect(() => {
    loadMoreItems()
  }, [])

  function loadMoreItems() {
    setIsFetching(true)
    axios({
      method: 'GET',
      url: '/api/v1/warehouse/compare-stock-list',
      params: { _page: page, _limit: 30 },
    })
      .then((res) => {
        setItems((prev) => [...new Set([...prev, ...res.data.response.items])])
        setPage((prevPageNumber) => prevPageNumber + 1)
        setHasMore(res.data.response.items.length > 0)
        setTotalItem(res.data.response.totalItems)
        setIsFetching(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <Layout title={'Compare Stock'}>
      <div
        className='align-items-center searchInventory shadow-sm p-3 bg-white rounded'
        style={{ display: 'flex', width: '100%' }}
      >
        <div className='col-auto text-end fw-bold' style={{ width: '50%' }}>
          Total Items: {totalItem}
        </div>
        <div className='col-auto text-end' style={{ width: '40%' }}>
          <button
            type='button'
            class='btn btn-primary btn-sm'
            onClick={() => navigate('/admin/warehouse')}
          >
            Return
          </button>
        </div>
        <div className='col-auto text-end' style={{ width: '10%' }}>
          <button
            type='button'
            class='btn btn-primary btn-sm'
            onClick={() => {
              window.scrollTo({ top: 0, left: 0 })
            }}
          >
            <i class='bi bi-arrow-up-circle'></i>
          </button>
        </div>
      </div>
      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '80px' }}>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Description</th>
                <th>Stock Qty</th>
                <th>MW-102 Qty</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                if (items.length === index + 1) {
                  return (
                    <>
                      <tr className='itemMainInfo' key={index}>
                        <td>{item._id}</td>
                        <td>{item.description}</td>
                        <td>{item.quantity}</td>
                        <td>{item.mw102Qty}</td>
                      </tr>
                      {isFetching && (
                        <tr>
                          <td className='text-center fw-bold' colSpan={4}>
                            Fetching items...
                          </td>
                        </tr>
                      )}

                      <tr ref={lastElementRef} key={index} className='endData'>
                        <td
                          colSpan={4}
                          className='text-center'
                          style={{ color: '#19692c' }}
                        >
                          Results retrieve completed
                        </td>
                      </tr>
                    </>
                  )
                } else {
                  return (
                    <>
                      <tr className='itemMainInfo' key={index}>
                        <td>{item._id}</td>
                        <td>{item.description}</td>
                        <td>{item.quantity}</td>
                        <td>{item.mw102Qty}</td>
                      </tr>
                    </>
                  )
                }
              })}
              {isFetching && (
                <tr>
                  <td colSpan={4} className='text-center'>
                    <div className='loader'></div>
                  </td>
                </tr>
              )}
              {!isFetching && items.length === 0 && (
                <tr>
                  <td colSpan={4} className='text-center'>
                    No items to display.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default AdminCompareStock
