import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Container, Row, Col, Form, Button, Table, Card } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const BookOrderRequest = () => {
  const [name, setName] = useState('')
  const [locality, setLocality] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [books, setBooks] = useState([])
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getActiveBooks = async () => {
    try {
      const { data } = await axios.get('/api/v1/general/prebook/all-books')
      if (data?.success) {
        setBooks(data?.books.map((book) => ({ ...book, qty: 0 })))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getActiveBooks()
  }, [])

  const handleFocus = (index, field) => {
    const updatedBooks = [...books]
    if (updatedBooks[index][field] === 0) {
      updatedBooks[index][field] = ''
    }
    setBooks(updatedBooks)
  }

  const handleBlur = (index, field) => {
    const updatedBooks = [...books]
    if (updatedBooks[index][field] === '') {
      updatedBooks[index][field] = 0
    }
    setBooks(updatedBooks)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const hasValidQuantity = books.some((book) => book.qty > 0)
    if (!hasValidQuantity) {
      toast.error('Please enter the book you want to order')
      return
    }

    try {
      const bookOrders = books
        .filter((book) => book.qty > 0)
        .map((book) => ({
          bookTitle: book._id,
          orderQty: book.qty,
          name,
          locality,
          email,
          phone,
          address,
        }))

      const { data } = await axios.post('/api/v1/general/prebook/order/store', {
        orders: bookOrders,
      })

      if (data.success) {
        setName('')
        setLocality('')
        setEmail('')
        setPhone('')
        setAddress('')
        setBooks(books.map((book) => ({ ...book, qty: 0 })))
        toast.success('Your book order is added successfully')

        if (isMobile) {
          window.scrollTo(0, 0) // Jump to the top of the page
        }
      } else {
        toast.error('Failed to add book order')
      }
    } catch (error) {
      console.log(error)
      toast.error('An error occurred while storing book orders')
    }
  }

  const tableCellStyle = isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}

  return (
    <Container className='h-100'>
      <Row className='justify-content-sm-center h-100'>
        <Col md={12}>
          <div className='text-center my-4'>
            <h1 className='fs-4 card-title fw-bold'>Book Order Request</h1>
          </div>
          <Card className='shadow-lg'>
            <Card.Body className=''>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className='mb-3'>
                    <Form.Group controlId='name'>
                      <Form.Label className='text-muted'>Name</Form.Label>
                      <Form.Control
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type='text'
                        required
                      />
                      <div className='invalid-feedback'>Name is invalid</div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Group controlId='locality'>
                      <Form.Label className='text-muted'>Locality</Form.Label>
                      <Form.Control
                        value={locality}
                        onChange={(e) => setLocality(e.target.value)}
                        type='text'
                        required
                      />
                      <div className='invalid-feedback'>
                        Locality is invalid
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Group controlId='email'>
                      <Form.Label className='text-muted'>Email</Form.Label>
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='text'
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Group controlId='phone'>
                      <Form.Label className='text-muted'>
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type='text'
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className='mb-3'>
                    <Form.Group controlId='address'>
                      <Form.Label className='text-muted'>Address</Form.Label>
                      <Form.Control
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type='text'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {isMobile ? <span className='fw-bold'>Book Title:</span> : ''}
                <Table responsive>
                  <thead style={isMobile ? { display: 'none' } : {}}>
                    <tr>
                      <th>No.</th>
                      <th>Book Title</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {books?.map((book, index) => (
                      <React.Fragment key={book._id}>
                        <tr style={isMobile ? { borderBottom: 'none' } : {}}>
                          <td
                            colSpan={isMobile ? 5 : 1}
                            style={
                              isMobile
                                ? { ...tableCellStyle, fontWeight: 'bold' }
                                : {}
                            }
                          >
                            {isMobile
                              ? `${index + 1}. ${book.title}`
                              : index + 1}
                          </td>
                          {!isMobile && (
                            <td style={tableCellStyle}>{book.title}</td>
                          )}
                          {!isMobile && (
                            <td style={tableCellStyle}>
                              <Form.Control
                                type='number'
                                value={book.qty}
                                onChange={(e) => {
                                  // Create a new array with updated quantities
                                  const updatedBooks = books.map((b, i) => {
                                    if (i === index) {
                                      return {
                                        ...b,
                                        qty: parseInt(e.target.value, 10) || 0,
                                      }
                                    }
                                    return b
                                  })
                                  // Update the books state with the new array
                                  setBooks(updatedBooks)
                                }}
                                onFocus={() => handleFocus(index, 'qty')}
                                onBlur={() => handleBlur(index, 'qty')}
                              />
                            </td>
                          )}
                        </tr>
                        {isMobile && (
                          <tr>
                            <td colSpan={5} style={tableCellStyle}>
                              <div style={{ marginBottom: '0.5rem' }}>
                                <span>Quantity: </span>
                                <Form.Control
                                  type='number'
                                  value={book.qty}
                                  onChange={(e) => {
                                    // Create a new array with updated quantities
                                    const updatedBooks = books.map((b, i) => {
                                      if (i === index) {
                                        return {
                                          ...b,
                                          qty:
                                            parseInt(e.target.value, 10) || 0,
                                        }
                                      }
                                      return b
                                    })
                                    // Update the books state with the new array
                                    setBooks(updatedBooks)
                                  }}
                                  onFocus={() => handleFocus(index, 'qty')}
                                  onBlur={() => handleBlur(index, 'qty')}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
                <div className='text-center'>
                  <Button
                    type='submit'
                    variant='primary'
                    className={`${isMobile ? 'btn-lg' : ''}`}
                    style={
                      isMobile
                        ? { padding: '0.6rem 1.5rem', fontSize: '1.2rem' }
                        : {}
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  )
}

export default BookOrderRequest
