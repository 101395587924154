import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

const AdminStockCategory = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [eventTitle, setEventTitle] = useState('')

  useEffect(() => {
    const fetchEventTitle = async () => {
      try {
        const response = await axios.get(
          `/api/v1/admin/stock-check/get-title/${id}`
        )
        setEventTitle(response.data.event)
      } catch (error) {
        console.error('Failed to fetch event title:', error)
      }
    }

    if (id) {
      fetchEventTitle()
    }
  }, [id])

  return (
    <Layout title={'Stock Category'}>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{
          width: '100%',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ height: '100%' }}
        >
          <div className='fw-bold'>{eventTitle}</div>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/admin/stock-check-list')}
          >
            Back
          </button>
        </div>
      </div>
      <div className='container' style={{ paddingTop: '70px' }}>
        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to={`/admin/stock-check/er-shop/${id}`}
                className='btn btn-outline-primary'
                style={{ fontSize: '20px' }}
              >
                ER Shop
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to={`/admin/stock-check/mobile/${id}`}
                className='btn btn-outline-primary'
                style={{ fontSize: '20px' }}
              >
                Mobile
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to={`/admin/stock-check/rack-box/${id}`}
                className='btn btn-outline-primary'
                style={{ fontSize: '20px' }}
              >
                MW-102
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AdminStockCategory
