import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;

const AdminUpdateProduct = () => {
  const params = useParams();
  const [name, setName] = useState("");
  const [refcode, setRefcode] = useState("");
  const [ibsn, setIbsn] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  // const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState("");

  // Get All Category
  // const getAllCategory = async () => {
  //   try {
  //     const { data } = await axios.get("/api/v1/category/all-category");
  //     if (data?.success) {
  //       setCategories(data?.categoryList);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllCategory();
  // }, []);

  // Get Single Product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/product/product-details/${params.id}`
      );
      setName(data.getProduct.name);
      setRefcode(data.getProduct.refcode);
      setIbsn(data.getProduct.ibsn);
      setPrice(data.getProduct.price);
      setDiscount(data.getProduct.discount);
      setQuantity(data.getProduct.quantity);
      // setCategory(data.getProduct.category);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
    //eslint-disable-next-line
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `/api/v1/product/update-product/${params.id}`,
        {
          name,
          refcode,
          ibsn,
          price,
          discount,
          quantity,
          // category,
        }
      );

      if (data?.success) {
        setSuccessMsg(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  return (
    <Layout title={"Create New Product"}>
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-sm-center h-100">
            <div className="col-md-7">
              <div className="text-center my-3">
                <h1 className="fs-4 card-title fw-bold mb-4">Update Product</h1>
              </div>
              <div className="card shadow-lg">
                <div className="card-body p-4">
                  <form onSubmit={handleUpdate}>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="name">
                        Name
                      </label>
                      <input
                        id="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control"
                        name="name"
                        required
                      />
                      <div className="invalid-feedback">Name is invalid</div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="refcode">
                        Item Code
                      </label>
                      <input
                        id="refcode"
                        value={refcode}
                        onChange={(e) => setRefcode(e.target.value)}
                        type="text"
                        className="form-control"
                        name="refcode"
                        required
                      />
                      <div className="invalid-feedback">
                        Item Code is invalid
                      </div>
                    </div>

                    {/* <div className="mb-3">
                      <label className="mb-2 text-muted">Category</label>
                      <Select
                        value={category}
                        onChange={(value) => {
                          setCategory(value);
                        }}
                        bordered={false}
                        size=""
                        className="form-select mb-3"
                      >
                        {categories?.map((data) => (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    </div> */}

                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="refcode">
                        IBSN
                      </label>
                      <input
                        id="ibsn"
                        value={ibsn}
                        onChange={(e) => setIbsn(e.target.value)}
                        type="text"
                        className="form-control"
                        name="ibsn"
                      />
                      <div className="invalid-feedback">IBSN is invalid</div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted">Price</label>
                      <input
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        type="number"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">Price is invalid</div>
                    </div>

                    <div className="mb-3">
                      <label className="mb-2 text-muted">Discount</label>
                      <input
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        type="number"
                        className="form-control"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="mb-2 text-muted">Quantity</label>
                      <input
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        type="number"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">
                        Quantity is invalid
                      </div>
                    </div>
                    {successMsg ? (
                      <div
                        className="mb-3"
                        style={{
                          color: "green",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {successMsg}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-outline-primary ms-auto mx-5"
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn btn-outline-secondary ms-auto"
                        onClick={() => navigate("/admin/products")}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="text-center mt-5 text-muted">
                Copyright © 2023 Gospel Book Centre PLT
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AdminUpdateProduct;
