import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const UserPackingListIndex = () => {
  const navigate = useNavigate()
  const [events, setEvents] = useState([])

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get('/api/v1/user/packing-list/events')
        setEvents(data)
      } catch (error) {
        console.log('Failed to fetch events:', error)
      }
    }

    fetchEvents()
  }, [])

  const handleViewDetails = (bookId) => {
    navigate(`/user/packing-list/details/${bookId}`)
  }

  return (
    <Layout title={'Packing List'}>
      <div className='pt-3 mx-3'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Event</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event._id}>
                <td>
                  {new Date(event.recordDate).toISOString().split('T')[0]}
                </td>
                <td>{event.event}</td>
                <td style={{ textAlign: 'right' }}>
                  <button
                    onClick={() => handleViewDetails(event._id)}
                    className='btn btn-outline-success btn-sm'
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default UserPackingListIndex
