// TrackingLogModal.js
import React from 'react'
import { Modal } from 'react-bootstrap'

const TrackingLogModal = ({ show, onHide, logs, trackingNo }) => (
  <Modal show={show} onHide={onHide} size='lg'>
    <Modal.Header closeButton>
      <Modal.Title style={{ fontSize: '20px' }}>
        Tracking No: {trackingNo}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {logs.map((log, index) => (
        <div key={index} className='mb-2'>
          <div className='fw-bold' style={{ color: '#2f4483' }}>
            {log.date}
          </div>
          <div className='fw-bold'>{log.process}</div>
          <div>{log.processSummary}</div>
          {log.proof && (
            <div>
              Proof:{' '}
              <a href={log.proof} target='_blank' rel='noopener noreferrer'>
                Show
              </a>
            </div>
          )}
        </div>
      ))}
    </Modal.Body>
  </Modal>
)

export default TrackingLogModal
