import React, { useState } from 'react'

const AdminEditNonAutoCountData = ({ value, onSave, className, field }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState(
    field === 'price' && value ? parseFloat(value).toFixed(2) : value // Format price or leave as is for other fields
  )

  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    setIsEditing(false)
    if (currentValue !== value) {
      const formattedValue =
        field === 'price' && currentValue
          ? parseFloat(currentValue).toFixed(2)
          : currentValue
      onSave(formattedValue)
    }
  }

  const handleChange = (e) => {
    setCurrentValue(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false)
      if (currentValue !== value) {
        const formattedValue =
          field === 'price' && currentValue
            ? parseFloat(currentValue).toFixed(2)
            : currentValue
        onSave(formattedValue)
      }
    }
    if (e.key === 'Escape') {
      setCurrentValue(
        field === 'price' && value ? parseFloat(value).toFixed(2) : value
      ) // Reset to formatted value on Escape
      setIsEditing(false)
    }
  }

  return (
    <td className={className} onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input
          type='text'
          value={currentValue || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        <span onClick={handleDoubleClick}>
          {field === 'price' && value
            ? parseFloat(currentValue).toFixed(2) // Show price with two decimal places
            : value || ( // Show the value or a pencil icon if empty
                <i
                  className='bi bi-pencil-fill'
                  style={{
                    cursor: 'pointer',
                    color: 'grey',
                    fontSize: '12px',
                  }}
                ></i>
              )}
        </span>
      )}
    </td>
  )
}

export default AdminEditNonAutoCountData
