import React, { useState } from 'react'
import { Form, Button, Dropdown } from 'react-bootstrap'
import axios from 'axios'

const NonAutoCountNewProduct = ({ handleSave, closeLogModal, refreshData }) => {
  const [productData, setProductData] = useState({
    location: '',
    category: '',
    rack: '',
    box: '',
    itemCode: '',
    isbn: '',
    title: '',
    desc: '',
    author: '',
    publisher: '',
    price: '',
    qty: '',
    remark: '',
  })

  const [categories, setCategories] = useState([])
  const [locations, setLocations] = useState([])

  const [loadingLocation, setLoadingLocation] = useState(false) // New loading state for locations
  const [loadingCategory, setLoadingCategory] = useState(false) // New loading state for categories

  const [locationInputValue, setLocationInputValue] = useState('')
  const [categoryInputValue, setCategoryInputValue] = useState('')

  const [showLocationDropdown, setShowLocationDropdown] = useState(false)
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)

  const fetchCategories = async () => {
    setLoadingCategory(true) // Set loading state
    try {
      const { data } = await axios.get(
        '/api/v1/admin/non-autocount/get-category'
      )
      setCategories(data)
    } catch (error) {
      console.error('Error fetching categories:', error)
    }
    setLoadingCategory(false)
  }

  const fetchLocations = async () => {
    setLoadingLocation(true)
    try {
      const { data } = await axios.get(
        '/api/v1/admin/non-autocount/get-location'
      )
      setLocations(data)
    } catch (error) {
      console.error('Error fetching locations:', error)
    }
    setLoadingLocation(false)
  }

  // Handle Category Input
  const handleCategoryInput = (e) => {
    const value = e.target.value
    setCategoryInputValue(value)
    setShowCategoryDropdown(true)
  }

  const handleSelectCategory = (category) => {
    setCategoryInputValue(category)
    setProductData((prevData) => ({
      ...prevData,
      category: category,
    }))
    setShowCategoryDropdown(false) // Hide category dropdown after selecting
  }

  // Handle Location Input
  const handleLocationInput = (e) => {
    const value = e.target.value
    setLocationInputValue(value) // Track input value for location
    setShowLocationDropdown(true) // Show location dropdown when typing
  }

  const handleSelectLocation = (location) => {
    setLocationInputValue(location) // Set selected location as input value
    setProductData((prevData) => ({
      ...prevData,
      location: location, // Update product data with selected location
    }))
    setShowLocationDropdown(false) // Hide location dropdown after selecting
  }

  const handleSubmit = async () => {
    try {
      // Find category and location by their names/values and get their IDs
      let categoryObj = categories.find(
        (category) => category.categoryName === categoryInputValue
      )
      let locationObj = locations.find(
        (location) => location.locationName === locationInputValue
      )

      // If category doesn't exist, create a new one
      if (!categoryObj && categoryInputValue) {
        const { data: newCategory } = await axios.post(
          '/api/v1/admin/non-autocount/add-new-category',
          {
            categoryName: categoryInputValue,
          }
        )
        categoryObj = newCategory
      }

      // If location doesn't exist, create a new one
      if (!locationObj && locationInputValue) {
        const { data: newLocation } = await axios.post(
          '/api/v1/admin/non-autocount/add-new-location',
          {
            locationName: locationInputValue,
          }
        )
        locationObj = newLocation
      }

      await axios.post('/api/v1/admin/non-autocount/add-new-data', {
        location: locationObj?._id, // Send the location's ObjectId
        category: categoryObj?._id, // Send the category's ObjectId
        rack: productData.rack,
        box: productData.box,
        itemCode: productData.itemCode,
        isbn: productData.isbn,
        title: productData.title,
        desc: productData.desc,
        author: productData.author,
        publisher: productData.publisher,
        price: productData.price,
        qty: productData.qty,
        remark: productData.remark,
      })
      refreshData()
      // Handle save callback and reset form data
      handleSave(productData)

      setProductData({
        location: '',
        category: '',
        rack: '',
        box: '',
        itemCode: '',
        isbn: '',
        title: '',
        desc: '',
        author: '',
        publisher: '',
        price: '',
        qty: '',
        remark: '',
      })
      setLocationInputValue('')
      setCategoryInputValue('')

      closeLogModal()
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  return (
    <div>
      <Form>
        <Form.Group className='mb-3' controlId='formLocation'>
          <Form.Label>Location</Form.Label>
          <Form.Control
            type='text'
            placeholder='Select or type a location'
            value={locationInputValue}
            onChange={handleLocationInput}
            onFocus={() => {
              setShowLocationDropdown(true)
              fetchLocations()
            }}
            onBlur={() => setTimeout(() => setShowLocationDropdown(false), 200)}
            autoComplete='off'
          />

          {showLocationDropdown && (
            <Dropdown.Menu show>
              {loadingLocation ? (
                <Dropdown.Item disabled>Loading...</Dropdown.Item>
              ) : (
                locations
                  .filter((loc) =>
                    loc.locationName
                      .toLowerCase()
                      .includes(locationInputValue.toLowerCase())
                  )
                  .map((location) => (
                    <Dropdown.Item
                      key={location._id}
                      onClick={() =>
                        handleSelectLocation(location.locationName)
                      }
                    >
                      {location.locationName}
                    </Dropdown.Item>
                  ))
              )}
            </Dropdown.Menu>
          )}
        </Form.Group>

        <Form.Group className='mb-3' controlId='formCategory'>
          <Form.Label>Category</Form.Label>
          <Form.Control
            type='text'
            placeholder='Select or type a category'
            value={categoryInputValue}
            onChange={handleCategoryInput}
            onFocus={() => {
              setShowCategoryDropdown(true)
              fetchCategories() // Trigger fetching of categories on focus
            }}
            onBlur={() => setTimeout(() => setShowCategoryDropdown(false), 200)}
            autoComplete='off'
          />

          {showCategoryDropdown && (
            <Dropdown.Menu show>
              {loadingCategory ? (
                <Dropdown.Item disabled>Loading...</Dropdown.Item>
              ) : (
                categories
                  .filter((cat) =>
                    cat.categoryName
                      .toLowerCase()
                      .includes(categoryInputValue.toLowerCase())
                  )
                  .map((category) => (
                    <Dropdown.Item
                      key={category._id}
                      onClick={() =>
                        handleSelectCategory(category.categoryName)
                      }
                    >
                      {category.categoryName}
                    </Dropdown.Item>
                  ))
              )}
            </Dropdown.Menu>
          )}
        </Form.Group>

        <Form.Group className='mb-3' controlId='formRack'>
          <Form.Label>Rack No.</Form.Label>
          <Form.Control
            type='text'
            name='rack'
            value={productData.rack}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                rack: e.target.value,
              }))
            }
            placeholder='Enter Rack No.'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBox'>
          <Form.Label>Box No.</Form.Label>
          <Form.Control
            type='text'
            name='box'
            value={productData.box}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                box: e.target.value,
              }))
            }
            placeholder='Enter Box No.'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formIsbn'>
          <Form.Label>Item Code</Form.Label>
          <Form.Control
            type='text'
            name='itemCode'
            value={productData.itemCode}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                itemCode: e.target.value,
              }))
            }
            placeholder='Enter Item Code'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formIsbn'>
          <Form.Label>ISBN</Form.Label>
          <Form.Control
            type='text'
            name='isbn'
            value={productData.isbn}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                isbn: e.target.value,
              }))
            }
            placeholder='Enter ISBN'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formTitle'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            name='title'
            value={productData.title}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                title: e.target.value,
              }))
            }
            placeholder='Enter Title'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formDescription'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            name='desc'
            value={productData.desc}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                desc: e.target.value,
              }))
            }
            placeholder='Enter Description'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formAuthor'>
          <Form.Label>Author</Form.Label>
          <Form.Control
            type='text'
            name='author'
            value={productData.author}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                author: e.target.value,
              }))
            }
            placeholder='Enter Author'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formPublisher'>
          <Form.Label>Publisher</Form.Label>
          <Form.Control
            type='text'
            name='publisher'
            value={productData.publisher}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                publisher: e.target.value,
              }))
            }
            placeholder='Enter Publisher'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formQuantity'>
          <Form.Label>Quantity</Form.Label>
          <Form.Control
            type='number'
            name='qty'
            value={productData.qty}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                qty: e.target.value,
              }))
            }
            placeholder='Enter Quantity'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formPrice'>
          <Form.Label>Price</Form.Label>
          <Form.Control
            type='number'
            name='price'
            value={productData.price}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                price: e.target.value,
              }))
            }
            placeholder='Enter Price'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formRemark'>
          <Form.Label>Remark</Form.Label>
          <Form.Control
            type='text'
            name='remark'
            value={productData.remark}
            onChange={(e) =>
              setProductData((prevData) => ({
                ...prevData,
                remark: e.target.value,
              }))
            }
            placeholder='Enter Remark'
          />
        </Form.Group>

        <div className='text-end'>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant='secondary' onClick={closeLogModal} className='ms-4'>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default NonAutoCountNewProduct
