import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from '../../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import BoxNoSelect from './BoxNoSelect'
import { saveAs } from 'file-saver'

const AdminRackBoxSelect = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [rackBoxData, setRackBoxData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [boxList, setBoxList] = useState([])
  const [selectedRack, setSelectedRack] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loadingBoxList, setLoadingBoxList] = useState(false)

  const fetchRackBoxData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check-store/rack-box/${id}`
      )
      setRackBoxData(response.data)
    } catch (error) {
      setError('Failed to load Rackbox data')
      console.error('Error fetching rack box data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRackBoxData()
  }, [id])

  // Group rack numbers by their first letter
  const groupRackNumbersByAlphabet = (rackData) => {
    const groupedData = rackData.reduce((acc, rack) => {
      const firstLetter = rack.rackNo.charAt(0).toUpperCase() // Extract rackNo and get first letter
      if (!acc[firstLetter]) {
        acc[firstLetter] = []
      }
      acc[firstLetter].push(rack) // Push the entire rack object
      return acc
    }, {})
    return groupedData
  }

  // Fetch box list for selected rack
  const handleRackClick = async (rackNo) => {
    setSelectedRack(rackNo) // Set the selected rack to display the title immediately
    setLoadingBoxList(true)
    setIsModalOpen(true)

    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check-store/rack-box/${id}/boxes/${rackNo}`
      )
      const fetchedBoxList = response.data

      // If boxList contains only one box, navigate directly to that box
      if (fetchedBoxList.length === 1) {
        const singleBoxNo = fetchedBoxList[0].boxNo || 'empty'
        navigate(`/admin/stock-check/store-room/${rackNo}/${singleBoxNo}/${id}`)
      } else {
        setBoxList(fetchedBoxList)
      }
    } catch (error) {
      console.error('Failed to fetch box list:', error)
    } finally {
      setLoadingBoxList(false) // End loading the box list
    }
  }
  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setBoxList([])
    setSelectedRack(null)
  }

  const exportFile = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/stock-check-store/export/${id}`,
        { responseType: 'blob' }
      )

      // Extract filename from content-disposition header
      const contentDisposition = response.headers['content-disposition']
      let filename = 'download.xlsx' // Default filename if not specified
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1]
        }
      }

      saveAs(response.data, filename)
    } catch (error) {
      console.error('Failed to download the file:', error)
      toast.error('Failed to download the file.')
    }
  }

  const resetData = async () => {
    const confirmed = window.confirm(
      'Confirm DELETE All Data and Import again?'
    )
    if (confirmed) {
      try {
        await axios.delete(`/api/v1/admin/stock-check-store/reset-data/${id}`)
        toast.success('Data reset successfully!')
        fetchRackBoxData() // Refetch data after reset
      } catch (error) {
        toast.error('Failed to reset data.')
        console.error('Failed to reset data:', error)
      }
    }
  }
  return (
    <Layout title='Rack Box Data'>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{
          width: '100%',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ height: '100%' }}
        >
          <div className='fw-bold'>Select Rack No.</div>
          <button
            type='button'
            className='btn btn-outline-danger btn-sm ms-5'
            onClick={resetData}
            disabled
          >
            <i class='bi bi-exclamation-triangle-fill'></i> Reset All Data
          </button>

          <button
            type='button'
            className='btn btn-outline-success btn-sm ms-5'
            onClick={() => exportFile()}
          >
            Export All MW102 Data
          </button>

          <button
            type='button'
            className='btn btn-outline-primary btn-sm ms-5'
            onClick={() =>
              navigate(`/admin/stock-check/store-room/mismatch-qty/${id}`)
            }
          >
            Qty Mismatch/User Remarks
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate(`/admin/stock-check/details/${id}`)}
          >
            Return to Stock Check Dashboard
          </button>
        </div>
      </div>

      <div className='' style={{ paddingTop: '70px' }}>
        {loading ? (
          <div className='text-center'>
            <span>Loading...</span>
          </div>
        ) : error ? (
          <div className='text-center text-danger'>{error}</div>
        ) : (
          <div className='ps-5 pt-3 pb-5'>
            {rackBoxData && rackBoxData.length > 0 ? (
              <div className='d-flex flex-column'>
                {Object.entries(groupRackNumbersByAlphabet(rackBoxData)).map(
                  ([letter, racks]) => (
                    <div className='d-flex flex-wrap' key={letter}>
                      <span
                        className='fw-bold me-3 mt-2'
                        style={{ fontSize: '18px', width: '10px' }}
                      >
                        {letter}
                      </span>
                      {racks.map((rack) => (
                        <button
                          key={rack.rackNo}
                          className='btn btn-outline-success m-2'
                          style={{
                            width: '150px',
                            textAlign: 'center',
                            backgroundColor:
                              rack.rackStatus === 1 ? '#0d6efd' : 'initial', // Red if boxStatus is 1
                            color: rack.rackStatus === 1 ? 'white' : '#198754',
                          }}
                          onClick={() => handleRackClick(rack.rackNo)} // Fetch box numbers when clicked
                        >
                          {rack.rackNo}
                        </button>
                      ))}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div>No racks found.</div>
            )}
          </div>
        )}

        <BoxNoSelect
          isOpen={isModalOpen} // Use `isOpen` instead of `isVisible`
          selectedRack={selectedRack}
          id={id}
          boxList={boxList}
          loading={loadingBoxList}
          onClose={handleCloseModal}
        />
      </div>
    </Layout>
  )
}

export default AdminRackBoxSelect
