import React from "react";

const EditTableRow = ({
  editFormData,
  handleEditFormChange,
  handleEditFormSubmit,
  handleCancelClick,
}) => {
  return (
    <tr className="stockContentRow">
      <td>
        <input
          class="form-control"
          type="text"
          name="acLocation"
          onFocus={(e) => e.target.select()}
          value={editFormData.acLocation}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
        <input
          class="form-control"
          type="text"
          name="rackNo"
          onFocus={(e) => e.target.select()}
          value={editFormData.rackNo}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
        <input
          class="form-control"
          type="text"
          name="boxNo"
          onFocus={(e) => e.target.select()}
          value={editFormData.boxNo}
          onChange={handleEditFormChange}
        />
      </td>

      <td>
        <input
          class="form-control"
          type="number"
          name="quantity"
          required
          onFocus={(e) => e.target.select()}
          value={editFormData.quantity}
          onChange={handleEditFormChange}
        />
      </td>
      <td colSpan={2}>
        <input
          class="form-control"
          type="text"
          name="remark"
          onFocus={(e) => e.target.select()}
          value={editFormData.remark}
          onChange={handleEditFormChange}
        />
      </td>
      <td>
        <button
          type="submit"
          class="btn btn-outline-success btn-sm me-2"
          onClick={handleEditFormSubmit}
        >
          <i class="bi bi-check-lg"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-danger btn-sm"
          onClick={handleCancelClick}
        >
          <i class="bi bi-x-lg"></i>
        </button>
      </td>
    </tr>
  );
};

export default EditTableRow;
