import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/auth'

const UserPreOrderBookList = () => {
  const navigate = useNavigate()
  const [books, setBooks] = useState([])
  const [auth] = useAuth()

  const getAllPreBooks = async () => {
    try {
      const token = auth?.token
      const { data } = await axios.get('/api/v1/user/pre-order/all-books', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (data?.success) {
        setBooks(data?.prebooks)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllPreBooks()
  }, [])

  const handleViewDetails = (bookId) => {
    navigate(`/user/pre-order/books/list/details/${bookId}`)
  }
  return (
    <Layout title={'Pre-books List'}>
      <div className='pt-3 mx-3'>
        <h5 className='mb-3 text-center'>Pre-Order Records</h5>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Title</th>
              <th className='text-center'>Remaining Vol.</th>
              <th className='text-center'>Last Vol.</th>
              <th className='text-center'>Status</th>
              <th className='text-center'>Total Orders</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {books?.map((book, index) => (
              <tr key={index}>
                <td>{book.title}</td>
                <td className='text-center'>{book.vol}</td>
                <td className='text-center'>{book.lastVol}</td>
                <td
                  className='text-center'
                  style={{ color: book.status === 1 ? 'green' : 'grey' }}
                >
                  {book.status === 1 ? 'Active' : 'Inactive'}
                </td>
                <td className='text-center'>{book.totalOrders}</td>
                <td style={{ textAlign: 'right' }}>
                  <button
                    onClick={() => handleViewDetails(book._id)}
                    className='btn btn-outline-success btn-sm'
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default UserPreOrderBookList
