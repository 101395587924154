import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ProductSaleDetails from "./ProductSaleDetails";

const AdminProductList = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");

  const [visibleModal, setVisibleModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [saleRecords, setSaleRecords] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    getProductCount();
  }, []);

  // Get All Products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/v1/product/all-product/1");
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // Get Total Count
  const getProductCount = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/product-count");
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!search) getAllProducts();
  }, [search]);

  // Load More
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/all-product/${page}`);
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);

  useEffect(() => {
    if (search.length > 3) searchProductResult();
  }, [search]);

  // Search Product
  const searchProductResult = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/search-product", {
        search,
      });
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  const clearInput = () => {
    setSearch("");
    getAllProducts();
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVisibleModal(false);
  };

  const getProductSaleRecord = async (productId, productName) => {
    try {
      const { data } = await axios.post(
        "/api/v1/product/product-sale-details",
        { productId }
      );
      setSaleRecords(data?.productSale);
      setProductName(productName);
      setVisibleModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Products"}>
      <div className="col-md-12">
        <div className="text-end me-2 pt-3">
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => navigate("/admin/product/new")}
          >
            Add New Product
          </button>
        </div>

        <div
          className="g-3 align-items-center ms-2"
          style={{ display: "flex" }}
        >
          <div className="col-auto" style={{ width: "50%" }}>
            <input
              type="text"
              className="form-control"
              value={search}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-outline-secondary"
              onClick={clearInput}
            >
              Clear
            </button>
          </div>
        </div>

        <table className="table mt-2">
          <thead>
            <tr>
              <th scope="col">Item Code</th>
              <th scope="col">Name</th>
              <th className="text-end" scope="col">
                Qty.
              </th>
              <th className="text-end" scope="col">
                Sales
              </th>
              <th className="text-end" scope="col">
                Price (RM)
              </th>
              <th className="text-end" scope="col">
                Dis. (%)
              </th>
              <th scope="col">IBSN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <>
                <tr>
                  <td>{product.refcode}</td>
                  <td>{product.name}</td>
                  <td className="text-end">{product.quantity}</td>
                  <td className="text-end">
                    <span
                      onClick={() =>
                        getProductSaleRecord(product._id, product.name)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {product.saleQty}
                    </span>
                  </td>
                  <td className="text-end">
                    {(Math.round(product.price * 100) / 100).toFixed(2)}
                  </td>
                  <td className="text-end">{product.discount}</td>
                  <td>{product.ibsn}</td>
                  <td className="text-end">
                    <button
                      className="btn btn-outline-primary btn-sm border-0 ms-2"
                      onClick={() => {
                        navigate(`/admin/product/${product._id}`);
                      }}
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} className="text-center">
                {products && products.length < total && !search && (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>Load More</>
                    )}
                  </button>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <Modal
        onCancel={() => setVisibleModal(false)}
        footer={null}
        open={visibleModal}
        width={1000}
      >
        <ProductSaleDetails
          productName={productName}
          saleRecords={saleRecords != null ? saleRecords : ""}
          closeModal={closeModal}
        />
      </Modal>
    </Layout>
  );
};

export default AdminProductList;
