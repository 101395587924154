import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Layout from '../../../components/Layout/Layout'
import { toast } from 'react-toastify'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import NonAutoCountNewProduct from './NewProduct'
import AdminEditNonAutoCountData from './EditContent'
import AdminEditNonAutoCountSelectData from './EditContentSelect'
import AdminEditLocationSelectData from './EditLocationSelect'
import AdminNonAutoCountCategory from './Category'
import AdminNonAutoCountLocation from './Location'

const AdminNonAutoCountIndex = () => {
  const navigate = useNavigate()

  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [filterQuery, setFilterQuery] = useState('')

  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [visibleRows, setVisibleRows] = useState({}) // State to track which row's info is visible
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  const [locationModalVisible, setLocationModalVisible] = useState(false)

  useEffect(() => {
    const shouldFetch = filterQuery.length > 3 || filterQuery === ''
    if (shouldFetch) {
      fetchData(page)
    }
  }, [page, filterQuery])

  useEffect(() => {
    if (filterQuery.length > 3 || filterQuery === '') {
      setPage(1)
    }
  }, [filterQuery])

  const fetchData = async (newPage = 1, showToast = false) => {
    setIsLoading(true)
    try {
      const response = await axios.get('/api/v1/admin/non-autocount/get-data', {
        params: {
          page: newPage,
          limit: 30,
          search: filterQuery,
        },
      })
      const { data, totalPages } = response.data
      if (newPage === 1) {
        setItems(data)
      } else {
        setItems((prev) => [...prev, ...data])
      }
      setHasMore(newPage < totalPages)
      if (showToast) {
        toast.success('Data is up to date')
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
      setItems([])
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveNewProduct = () => {
    setVisibleLogModal(false)
  }

  const closeModal = () => setVisibleLogModal(false)

  const handleSave = async (index, field, value) => {
    try {
      const itemId = items[index]._id
      const { data } = await axios.put(
        `/api/v1/admin/non-autocount/update/${itemId}`,
        { [field]: value }
      )

      if (data.success) {
        const updatedItems = [...items]
        updatedItems[index] = {
          ...updatedItems[index],
          [field]: value,
        }
        setItems(updatedItems) // Update the UI with the updated items
      } else {
        console.error('Failed to update the item on the server.')
      }
    } catch (error) {
      console.error('Error updating item:', error) // Log the error in case of failure
    }
  }

  const handleFilterChange = (value) => {
    setFilterQuery(value)
  }

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const toggleVisibility = (id) => {
    setVisibleRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle visibility for the specific row by its id
    }))
  }

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Cofirm delete item?')
    if (confirmDelete) {
      try {
        await axios.delete(`/api/v1/admin/non-autocount/delete/${id}`)
        setItems((prevItems) => prevItems.filter((item) => item._id !== id)) // Update UI after deletion
        toast.success('Item deleted successfully')
      } catch (error) {
        toast.error('Failed to delete item')
      }
    }
  }

  const toggleCategoryModal = () => {
    setCategoryModalVisible(!categoryModalVisible)
  }
  const toggleLocationModal = () => {
    setLocationModalVisible(!locationModalVisible)
  }

  return (
    <Layout title='Non AutoCount List'>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{ width: '100%', position: 'fixed', height: '70px' }}
      >
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex'>
            <input
              type='text'
              className='form-control'
              style={{ width: '250px' }}
              placeholder='Search Code / ISBN / Title'
              onChange={(e) => handleFilterChange(e.target.value)}
              value={filterQuery}
            />
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={() => setFilterQuery('')}
            >
              Clear
            </button>
          </div>

          <div className='fw-bold' style={{ fontSize: '18px' }}>
            Non AutoCount List
          </div>

          <div className='d-flex ml-auto'>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={toggleCategoryModal}
            >
              Category
            </button>
            <button
              type='button'
              className='btn btn-primary btn-sm ms-4'
              onClick={toggleLocationModal}
            >
              Location
            </button>
            <button
              type='button'
              className='btn btn-success btn-sm ms-4'
              onClick={() => setVisibleLogModal(true)}
            >
              Add New Data
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm ms-4'
              onClick={() => navigate('/admin/dashboard')}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table actable' style={{ marginTop: '80px' }}>
          <thead>
            <tr>
              <th>Location</th>
              <th>Rack</th>
              <th>Box</th>
              <th>Code</th>
              <th>ISBN</th>
              <th>Title</th>
              <th>Category</th>
              <th className='text-center'>Qty</th>
              <th>Price (RM)</th>
              <th>Remark</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.length === 0 ? (
              <tr>
                <td colSpan='11' className='text-center'>
                  No records
                </td>
              </tr>
            ) : (
              items.map((item, index) => (
                <React.Fragment key={item._id}>
                  <tr>
                    <AdminEditLocationSelectData
                      value={item?.location?.locationName}
                      onSave={(selectedLocation) =>
                        handleSave(index, 'location', selectedLocation._id)
                      }
                      currentLocationId={item?.location?._id}
                    />

                    <AdminEditNonAutoCountData
                      value={item.rack}
                      onSave={(value) => handleSave(index, 'rack', value)}
                    />
                    <AdminEditNonAutoCountData
                      value={item.box}
                      onSave={(value) => handleSave(index, 'box', value)}
                    />
                    <AdminEditNonAutoCountData
                      value={item.itemCode}
                      onSave={(value) => handleSave(index, 'itemCode', value)}
                    />
                    <AdminEditNonAutoCountData
                      value={item.isbn}
                      onSave={(value) => handleSave(index, 'isbn', value)}
                    />
                    <AdminEditNonAutoCountData
                      value={item.title}
                      onSave={(value) => handleSave(index, 'title', value)}
                    />
                    <AdminEditNonAutoCountSelectData
                      value={item?.category?.categoryName}
                      onSave={(selectedCategory) =>
                        handleSave(index, 'category', selectedCategory._id)
                      }
                      currentCategoryId={item?.category?._id}
                    />
                    <AdminEditNonAutoCountData
                      className='text-center'
                      value={item.qty}
                      onSave={(value) => handleSave(index, 'qty', value)}
                    />
                    <AdminEditNonAutoCountData
                      value={item.price}
                      field='price'
                      onSave={(value) => handleSave(index, 'price', value)}
                    />
                    <AdminEditNonAutoCountData
                      value={item.remark}
                      onSave={(value) => handleSave(index, 'remark', value)}
                    />
                    <td className='text-end'>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleVisibility(item._id)}
                      >
                        <i
                          className='bi-info-circle-fill'
                          style={{ color: '#00a6ff' }}
                        ></i>
                      </span>
                      <span
                        className='ms-3'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(item._id)}
                      >
                        <i
                          className='bi bi-trash3-fill'
                          style={{ color: 'red' }}
                        ></i>
                      </span>
                    </td>
                  </tr>

                  {visibleRows[item._id] && (
                    <tr>
                      <td colSpan='11'>
                        <div style={{ padding: '5px' }}>
                          <div className='d-flex mb-1'>
                            <strong style={{ width: '120px' }}>
                              Description:{' '}
                            </strong>
                            <AdminEditNonAutoCountData
                              value={item.desc}
                              onSave={(value) =>
                                handleSave(index, 'desc', value)
                              }
                            />
                          </div>
                          <div className='d-flex mb-1'>
                            <strong style={{ width: '120px' }}>Author: </strong>
                            <AdminEditNonAutoCountData
                              value={item.author}
                              onSave={(value) =>
                                handleSave(index, 'author', value)
                              }
                            />
                          </div>
                          <div className='d-flex mb-1'>
                            <strong style={{ width: '120px' }}>
                              Publisher:{' '}
                            </strong>
                            <AdminEditNonAutoCountData
                              value={item.publisher}
                              onSave={(value) =>
                                handleSave(index, 'publisher', value)
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div ref={lastElementRef}>{isLoading && <p>Loading...</p>}</div>

      <Modal
        closable={false}
        onCancel={closeModal}
        open={visibleLogModal}
        width={'60%'}
        footer={null}
      >
        <NonAutoCountNewProduct
          closeLogModal={closeModal}
          handleSave={handleSaveNewProduct}
          refreshData={() => fetchData(1)}
        />
      </Modal>

      <AdminNonAutoCountCategory
        visible={categoryModalVisible}
        onClose={toggleCategoryModal}
      />
      <AdminNonAutoCountLocation
        visible={locationModalVisible}
        onClose={toggleLocationModal}
      />
    </Layout>
  )
}

export default AdminNonAutoCountIndex
