import React, { Fragment, useEffect, useState } from 'react'
import useInfiniteScroll from './useInfiniteScroll'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import '../../../styles/AdminStyle.css'
import { Modal } from 'antd'
import AddStockForm from './AddStockForm'
import EditTableRow from './EditTableRow'
import ReadOnlyRow from './ReadOnlyRow'
import moment from 'moment'
import StockQtyTaken from './StockQtyTaken'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/auth'

const AdminInventoryList = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const navigate = useNavigate()
  const [auth] = useAuth()

  const [items, setItems] = useState([])
  const [searchData, setSearchData] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [updateDate, setUpdateDate] = useState('')

  // Setting the initial page
  const [page, setPage] = useState(0)
  // Need to know if there is more data
  const [HasMore, setHasMore] = useState(true)

  // Add and Edit Inventory
  const [location, setLocation] = useState('')
  const [rack, setRack] = useState('')
  const [box, setBox] = useState('')
  const [qty, setQty] = useState('')
  const [remark, setRemark] = useState('')

  const [productCode, setProductCode] = useState(null)
  const [productName, setProductName] = useState(null)
  const [editStockId, setEditStockId] = useState(null)

  const [editFormData, setEditFormData] = useState({
    acLocation: location,
    rackNo: rack,
    boxNo: box,
    quantity: qty,
    remark: remark,
  })

  const [addFormData, setAddFormData] = useState({
    acLocation: '',
    rackNo: '',
    boxNo: '',
    quantity: '',
    uomRate: '',
    remark: '',
    roomNo: '',
  })

  const [stockAddData, setStockAddData] = useState({
    quantity: '',
    remark: '',
  })

  const [visibleAddModal, setVisibleAddModal] = useState(false)

  const [visibleStockQtyModal, setVisibleStockQtyModal] = useState(false)

  const [lastElementRef] = useInfiniteScroll(
    HasMore ? loadMoreItems : () => {},
    isFetching
  )

  const [stockPending, isStockPending] = useState(0)
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    fetchLastUpdatedAt(setUpdateDate)
    pendingStock()
    loadMoreItems()
    setHasMore(false)
    setPage(0)
  }, [])

  useEffect(() => {
    if (searchData.length > 3) {
      searchProductResult()
    }
    setHasMore(false)
    setPage(0)
  }, [searchData])

  function loadMoreItems() {
    setIsFetching(true)

    axios({
      method: 'GET',
      url: '/api/v1/warehouse/autocount-list',
      headers: { Authorization: `Bearer ${auth?.token}` },
      params: { _page: page, _limit: 30 },
    })
      .then((res) => {
        setItems((prev) => {
          return [...new Set([...prev, ...res.data.response.items])]
        })

        setPage((prevPageNumber) => prevPageNumber + 1)
        setHasMore(res.data.response.items.length > 0)
        setIsFetching(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const fetchLastUpdatedAt = async (setter) => {
    try {
      const { data } = await axios.get(
        '/api/v1/warehouse/updated-date/stock-item'
      ) // Directly using the specific endpoint
      if (data.lastUpdatedAt) {
        setter(moment(data.lastUpdatedAt).format('YYYY-MM-DD HH:mm:ss'))
      }
    } catch (error) {
      console.error(
        'Failed to fetch last updated date:',
        error.response ? error.response.data : error.message
      )
    }
  }

  const pendingStock = async () => {
    try {
      const { data } = await axios.post('/api/v1/warehouse/pending-stock-qty')
      isStockPending(data.pending)
    } catch (error) {
      console.log(error)
    }
  }

  const refreshData = () => {
    fetchLastUpdatedAt(setUpdateDate)
    pendingStock()
    if (!searchData) refetchData()
    if (searchData.length > 2) searchProductResult()
    setHasMore(false)
    setPage(0)
  }

  function refetchData() {
    setIsFetching(true)

    axios({
      method: 'GET',
      url: '/api/v1/warehouse/autocount-list',
      headers: { Authorization: `Bearer ${auth?.token}` },
      params: { _page: 0, _limit: 30 },
    })
      .then((res) => {
        setItems(res.data.response.items)

        setPage((prevPageNumber) => prevPageNumber + 1)
        setHasMore(res.data.response.items.length > 0)
        setIsFetching(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const searchProductResult = async () => {
    try {
      const { data } = await axios.post('/api/v1/warehouse/search-stock', {
        searchData,
      })
      setItems(data?.products)
    } catch (error) {
      console.log(error)
    }
  }

  const clearInput = () => {
    setSearchData('')
    setExpandedRows([])
    setItems([])
    loadMoreItems()
  }

  const getSearchContent = (value) => {
    setSearchData(value)
    setExpandedRows([])
    if (!value.length) {
      setItems([])
      setPage(0)
      loadMoreItems()
    }
  }

  const handleEditClick = (e, product, itemCode) => {
    e.preventDefault()
    setProductCode(itemCode)
    setEditStockId(product._id)
    setLocation(product.acLocation)
    setRack(product.rackNo)
    setBox(product.boxNo)
    setQty(product.quantity)
    setRemark(product.remark)

    const formValues = {
      acLocation: product.acLocation,
      rackNo: product.rackNo,
      boxNo: product.boxNo,
      quantity: product.quantity,
      remark: product.remark,
    }

    setEditFormData(formValues)
  }

  const handleEditFormChange = (e) => {
    e.preventDefault()

    const fieldName = e.target.getAttribute('name')
    const fieldValue = e.target.value

    const newFormData = { ...editFormData }
    newFormData[fieldName] = fieldValue

    setEditFormData(newFormData)
  }

  const handleEditFormSubmit = async (e) => {
    e.preventDefault()

    const editedProduct = {
      stockId: editStockId,
      acLocation: editFormData.acLocation,
      rackNo: editFormData.rackNo,
      boxNo: editFormData.boxNo,
      quantity: editFormData.quantity,
      remark: editFormData.remark,
    }

    try {
      const { data } = await axios.put('/api/v1/warehouse/update-stock', {
        productCode,
        editedProduct,
      })

      const newItems = [...items]
      const index = items.findIndex((item) => item.itemCode === productCode)
      newItems[index] = data.product[0]
      setItems(newItems)
      setEditStockId(null)
      setProductCode(null)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancelClick = () => {
    setEditStockId(null)
    setProductCode(null)
  }

  const openAddModal = (productCode, productName) => {
    setProductCode(productCode)
    setProductName(productName)
    setVisibleAddModal(true)
  }

  // Add New Inventory
  const handleAddFormChange = (e) => {
    e.preventDefault()

    const fieldName = e.target.getAttribute('name')
    const fieldValue = e.target.value

    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue

    setAddFormData(newFormData)
  }

  const handleAddFormSubmit = async (e) => {
    e.preventDefault()

    const newProduct = {
      roomNo: addFormData.roomNo,
      acLocation: addFormData.acLocation,
      rackNo: addFormData.rackNo,
      boxNo: addFormData.boxNo,
      quantity: addFormData.quantity,
      uomRate: addFormData.uomRate,
      remark: addFormData.remark,
    }

    try {
      const { data } = await axios.post('/api/v1/warehouse/add-stock', {
        productCode,
        newProduct,
      })

      const newItems = [...items]
      const index = items.findIndex((item) => item.itemCode === productCode)
      newItems[index] = data.product[0]
      setItems(newItems)
      setProductCode(null)
      setVisibleAddModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleStockQtyUpdate = (e, product, description) => {
    e.preventDefault()
    setProductCode(product.itemCode)
    setProductName(description)
    setRack(product.rackNo)
    setBox(product.boxNo)
    setVisibleStockQtyModal(true)
  }

  const handleStockQtyChange = (e) => {
    e.preventDefault()

    const fieldName = e.target.getAttribute('name')
    const fieldValue = e.target.value
    const newFormData = { ...stockAddData }
    newFormData[fieldName] = fieldValue

    setStockAddData(newFormData)
  }

  const handleStockQtySubmit = async (e) => {
    e.preventDefault()

    const newProduct = {
      quantity: stockAddData.quantity,
      remark: stockAddData.remark,
    }
    const userId = auth?.user?._id
    try {
      const { data } = await axios.post('/api/v1/warehouse/stock-logs-add', {
        userId,
        productCode,
        productName,
        rack,
        box,
        newProduct,
      })

      setStockAddData({ quantity: '', remark: '' })
      setVisibleStockQtyModal(false)
      setAddFormData.quantity = ''
      setAddFormData.remark = ''
      pendingStock()
    } catch (error) {
      console.log(error)
    }
  }

  const closeModal = (e) => {
    e.preventDefault()
    setVisibleAddModal(false)
    setStockAddData({ quantity: '', remark: '' })
    setVisibleStockQtyModal(false)
  }

  const toggleRow = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((rowIndex) => rowIndex !== index)
        : [...prevExpandedRows, index]
    )
  }

  return (
    <Layout title={'Products'}>
      <div
        className='searchInventory shadow-sm p-3 bg-white rounded'
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
        }}
      >
        <div
          className='col-auto'
          style={{ width: isMobile ? '100%' : '45%', display: 'flex' }}
        >
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Search Item Code or Item Name'
            onChange={(e) => getSearchContent(e.target.value)}
            style={{ flex: 1 }}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={clearInput}
            style={{ marginLeft: '10px' }}
          >
            Clear
          </button>
        </div>

        {!isMobile && (
          <>
            <div
              className='col-auto text-center'
              style={{
                width: '20%',
                marginTop: '0',
              }}
            >
              Offline AutoCount Updated:
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                {updateDate && moment(updateDate).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
            <div
              className='col-auto text-end'
              style={{
                width: isMobile ? '100%' : '5%',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <button
                type='button'
                className='btn btn-info btn-sm'
                onClick={refreshData}
              >
                <i
                  className='bi bi-arrow-clockwise'
                  style={{ color: '#fff' }}
                ></i>
              </button>
            </div>
            <div
              className='col-auto text-end'
              style={{
                width: isMobile ? '100%' : '5%',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <button
                type='button'
                className='btn btn-info btn-sm'
                style={{ color: '#fff' }}
                onClick={() => navigate('excel')}
              >
                Excel
              </button>
            </div>
            <div
              className='col-auto text-end'
              style={{
                width: isMobile ? '100%' : '10%',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <button
                type='button'
                className='btn btn-outline-warning btn-sm'
                onClick={() => navigate('compare-stock')}
              >
                Compare
              </button>
            </div>
            <div
              className='col-auto text-end'
              style={{
                width: isMobile ? '100%' : '10%',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <button
                type='button'
                className='btn btn-outline-success btn-sm'
                onClick={() => navigate('stock-logs')}
              >
                Stock
                {stockPending !== 0 && (
                  <span className='badge bg-danger badge-number'>
                    {stockPending}
                  </span>
                )}
              </button>
            </div>
            <div
              className='col-auto text-end'
              style={{
                width: isMobile ? '100%' : '5%',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
              >
                <i className='bi bi-arrow-up-circle'></i>
              </button>
            </div>
          </>
        )}
      </div>

      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '80px' }}>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Desc</th>
                <th>Total Qty.</th>
                <th>Stock Bal.</th>
                <th>Price</th>
                <th>Item Grp.</th>
                <th>Item Type</th>
                <th>Barcode</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                if (
                  !searchData
                    ? items.length === index + 1
                    : items.length === index
                ) {
                  return (
                    // Referencing the last item to be watched by the observer
                    <tr ref={lastElementRef} key={index} className='endData'>
                      <td
                        colSpan={8}
                        className='text-center'
                        style={{ color: '#19692c' }}
                      >
                        Items retrieve completed
                      </td>
                    </tr>
                  )
                } else {
                  return (
                    <Fragment key={index}>
                      <tr
                        className='itemMainInfo'
                        onClick={() => toggleRow(index)}
                        style={{
                          backgroundColor:
                            item.isActive === 'Unchecked' ? '#ffe6e6' : '',
                        }}
                      >
                        <td
                          className='item-code-cell'
                          style={{
                            color: item.isActive === 'Checked' && '#590000',
                          }}
                        >
                          {item.itemCode}
                        </td>
                        <td className='description-cell'>
                          <span
                            style={{
                              color: item.isActive === 'Checked' && '#590000',
                            }}
                          >
                            {item.description}
                          </span>
                          {item.desc2 && (
                            <span
                              className='ms-2 mt-1'
                              style={{ fontSize: '15px' }}
                            >
                              ~ {item.desc2}
                            </span>
                          )}
                        </td>
                        <td className='text-center fw-bold'>
                          {item.totalBalQty}
                        </td>
                        <td>
                          {item.isActive === 'Checked' && (
                            <>
                              {item.location_detail && (
                                <>
                                  <span className='me-3 white-space'>
                                    ER Shop: {item.location_detail.erShopQty}
                                  </span>
                                  <span className='me-3 white-space'>
                                    Mobile: {item.location_detail.mobileQty}
                                  </span>
                                  <span className='me-3 white-space'>
                                    MW-102: {item.location_detail.mw102Qty}
                                  </span>
                                  {item.location_detail.ss15erQty !== 0 && (
                                    <span className='me-3 white-space'>
                                      SS15-ER: {item.location_detail.ss15erQty}
                                    </span>
                                  )}
                                  {item.location_detail.ss15mwQty !== 0 && (
                                    <span className='me-3 white-space'>
                                      SS15-MW: {item.location_detail.ss15mwQty}
                                    </span>
                                  )}
                                  {item.location_detail.ss15spQty !== 0 && (
                                    <span className='white-space'>
                                      SS15-SP: {item.location_detail.ss15spQty}
                                    </span>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td className='text-end'>
                          {(Math.round(item.price * 100) / 100).toFixed(2)}
                        </td>
                        <td>{item.itemGrp}</td>
                        <td>{item.itemType}</td>
                        <td>{item.barCode}</td>
                        <td>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              openAddModal(item.itemCode, item.description)
                            }
                          >
                            {item.isActive === 'Checked' &&
                              (auth?.user?._id === '658e28cb173937c3f849f2ce' ||
                                auth?.user?._id ===
                                  '65960e5fc2b5e5e3557ff94f') && (
                                <i className='bi bi-plus-square'></i>
                              )}
                          </span>
                        </td>
                      </tr>
                      {expandedRows.includes(index) &&
                        item.isActive === 'Checked' && (
                          <tr>
                            <td colSpan={8}>
                              <div
                                className='mb-1'
                                style={{ fontSize: '15px', color: '#2b4748' }}
                              >
                                {item.itemCode}
                                <span className='ms-3'>{item.description}</span>
                              </div>
                              {item.ac_stock.length > 0 &&
                                item.ac_stock.map((product, index) => (
                                  <div
                                    className={`${!isMobile ? 'mx-3' : ''}`}
                                    style={{ border: '2px solid #afced0' }}
                                  >
                                    <Fragment key={index}>
                                      {editStockId === product._id ? (
                                        <EditTableRow
                                          editFormData={editFormData}
                                          handleEditFormChange={
                                            handleEditFormChange
                                          }
                                          handleEditFormSubmit={
                                            handleEditFormSubmit
                                          }
                                          handleCancelClick={handleCancelClick}
                                        />
                                      ) : (
                                        <ReadOnlyRow
                                          auth={auth}
                                          itemCode={item.itemCode}
                                          description={item.description}
                                          product={product}
                                          handleEditClick={handleEditClick}
                                          handleStockQtyUpdate={
                                            handleStockQtyUpdate
                                          }
                                        />
                                      )}
                                    </Fragment>
                                  </div>
                                ))}
                            </td>
                          </tr>
                        )}
                    </Fragment>
                  )
                }
              })}
            </tbody>
          </table>
        </div>
        <div className='text-center'>
          {isFetching && <p>Fetching items...</p>}
        </div>
      </div>

      <Modal
        closable={false}
        onCancel={() => setVisibleAddModal(false)}
        footer={null}
        open={visibleAddModal}
      >
        <AddStockForm
          productCode={productCode}
          productName={productName}
          closeAddModal={closeModal}
          handleAddFormChange={handleAddFormChange}
          handleAddFormSubmit={handleAddFormSubmit}
        />
      </Modal>

      <Modal
        closable={false}
        onCancel={() => setVisibleStockQtyModal(false)}
        footer={null}
        open={visibleStockQtyModal}
      >
        <StockQtyTaken
          productCode={productCode}
          productName={productName}
          rack={rack}
          box={box}
          stockAddData={stockAddData}
          handleStockQtyChange={handleStockQtyChange}
          handleStockQtySubmit={handleStockQtySubmit}
          closeModal={closeModal}
        />
      </Modal>
    </Layout>
  )
}

export default AdminInventoryList
