import React from "react";

const AddStockForm = ({
  productCode,
  productName,
  closeAddModal,
  handleAddFormChange,
  handleAddFormSubmit,
}) => {
  return (
    <>
      <div className="text-center h5 shadow-sm pb-3 bg-white rounded">
        Add Stock
      </div>
      <form className="mt-3">
        <div className="h6 mb-3">
          <span className="me-2">{productCode}</span> {productName}
        </div>
        <div className="form-group row mb-2">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            Room No
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              name="roomNo"
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>

        <div className="form-group row mb-2">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            Location
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              name="acLocation"
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            Rack No
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              name="rackNo"
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            Box No
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              name="boxNo"
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>

        <div className="form-group row mb-2">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            Quantity
          </label>
          <div className="col-sm-8">
            <input
              type="number"
              name="quantity"
              required
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>

        <div className="form-group row mb-2">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            UOM Rate
          </label>
          <div className="col-sm-8">
            <input
              type="number"
              name="uomRate"
              required
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="" className="col-sm-4 h6 pt-3">
            Remark
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              name="remark"
              className="form-control mt-2"
              onFocus={(e) => e.target.select()}
              onChange={handleAddFormChange}
            />
          </div>
        </div>

        <div className="text-end mt-4">
          <button
            type="submit"
            class="btn btn-outline-success"
            onClick={handleAddFormSubmit}
          >
            <i class="bi bi-check-lg"></i> Submit
          </button>
          <button
            type="submit"
            class="btn btn-outline-danger ms-3"
            onClick={closeAddModal}
          >
            <i class="bi bi-x-lg"></i> Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default AddStockForm;
