import { Routes, Route } from 'react-router-dom'
import AdminDashboard from './pages/Admin/Dashboard'
import Login from './pages/Auth/Login'
import CashierDashboard from './pages/Cashier/Dashboard'
import AdminUsers from './pages/Admin/Users/UsersList'
import AdminCreateUser from './pages/Admin/Users/CreateUser'
import AdminCategoryList from './pages/Admin/Category/CategoryList'
import AdminSubcategoryList from './pages/Admin/Subcategory/SubcategoryList'
import AdminProductList from './pages/Admin/Product/ProductList'
import AdminCreateProduct from './pages/Admin/Product/CreateProduct'
import AdminUpdateProduct from './pages/Admin/Product/UpdateProduct'
import HomePage from './pages/HomePage'
import SaleRecords from './pages/Cashier/SaleRecords'
import PageNotFound from './pages/PageNotFound'
import UserRoute from './components/Routes/UserRoute'
import AdminRoute from './components/Routes/AdminRoute'
import CloseCounter from './pages/Cashier/CloseCounter'
import AdminEditUser from './pages/Admin/Users/EditUser'
import AdminReportList from './pages/Admin/Report/ReportList'
import AdminInventoryList from './pages/Admin/Inventory/InventoryList'
import AdminStockLog from './pages/Admin/Inventory/StockLog'
import AdminCompareStock from './pages/Admin/Inventory/CompareStock'
import AdminPreOrderBookList from './pages/Admin/General/PreOrderBookList'
import AdminCreatePreOrderBook from './pages/Admin/General/CreatePreOrderBook'
import AdminPreOrderBookRequest from './pages/Admin/General/PreOrderBookRequest'
import PreBookOrder from './pages/General/BookOrder'
import AdminEditPreOrderBook from './pages/Admin/General/EditPreOrderBook'
import AdminExcelFile from './pages/Admin/Inventory/ExcelFile'
import AdminPackingListIndex from './pages/Admin/PackingList/PackingListIndex'
import AdminPackingListDetails from './pages/Admin/PackingList/PackingListDetails'
import AdminGroupItemDetails from './pages/Admin/PackingList/GroupItem/GroupItemDetails'
import AdminGroupItemCreate from './pages/Admin/PackingList/GroupItem/GroupItemCreate'
import CashierRoute from './components/Routes/CashierRoute'
import UserDashboard from './pages/User/Dashboard'
import UserPackingListIndex from './pages/User/PackingList/PackingListIndex'
import UserPackingListDetails from './pages/User/PackingList/PackingListDetails'
import AdminTrackingList from './pages/Admin/Tracking/TrackingList'
import AdminStockCheckListIndex from './pages/Admin/StockCheck/StockCheckList'
import AdminStockCategory from './pages/Admin/StockCheck/StockCategory'
import AdminErShopCheckList from './pages/Admin/StockCheck/ErShop/ErShopCheckList'
import AdminRackBoxSelect from './pages/Admin/StockCheck/StoreRoom/RackBoxSelect'
import AdminStoreRoomCheckList from './pages/Admin/StockCheck/StoreRoom/StoreRoomCheckList'
import AdminMobileCheckList from './pages/Admin/StockCheck/Mobile/MobileCheckList'
import UserStockCategory from './pages/User/Stock Check/StockCategory'
import UserErShopCheckList from './pages/User/Stock Check/ErShop/ErShopCheckList'
import UserMobileCheckList from './pages/User/Stock Check/Mobile/MobileCheckList'
import UserRackBoxSelect from './pages/User/Stock Check/StoreRoom/RackBoxSelect'
import UserStoreRoomCheckList from './pages/User/Stock Check/StoreRoom/StoreRoomCheckList'
import AdminMobileMismatchQty from './pages/Admin/StockCheck/Mobile/MobileMismatchQty'
import AdminErMismatchQty from './pages/Admin/StockCheck/ErShop/ErMismatchQty'
import AdminStoreRoomMismatchQty from './pages/Admin/StockCheck/StoreRoom/StoreRoomMismatchQty'
import AdminNonAutoCountIndex from './pages/Admin/NonAutoCount/Index'
import AdminGroupItemEdit from './pages/Admin/PackingList/GroupItem/GroupItemEdit'
import UserPreOrderBookList from './pages/User/PreOrder/PreOrderBookList'
import UserPreOrderBookRequest from './pages/User/PreOrder/PreOrderBookRequest'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/pre-order/books' element={<PreBookOrder />} />

        <Route path='/cashier' element={<CashierRoute />}>
          <Route path='dashboard' element={<CashierDashboard />} />
          <Route path='transaction-records' element={<SaleRecords />} />
          <Route path='close-counter' element={<CloseCounter />} />
        </Route>

        {/* Admin */}
        <Route path='/admin' element={<AdminRoute />}>
          <Route path='dashboard' element={<AdminDashboard />} />

          {/* Users */}
          <Route path='users' element={<AdminUsers />} />
          <Route path='users/new' element={<AdminCreateUser />} />
          <Route path='users/:id' element={<AdminEditUser />} />
          <Route path='category' element={<AdminCategoryList />} />
          <Route path='subcategory' element={<AdminSubcategoryList />} />
          <Route path='products' element={<AdminProductList />} />
          <Route path='product/new' element={<AdminCreateProduct />} />
          <Route path='product/:id' element={<AdminUpdateProduct />} />
          <Route path='report' element={<AdminReportList />} />

          {/* Warehouse */}
          <Route path='warehouse' element={<AdminInventoryList />} />
          <Route path='warehouse/stock-logs' element={<AdminStockLog />} />
          <Route path='warehouse/excel' element={<AdminExcelFile />} />
          <Route
            path='warehouse/compare-stock'
            element={<AdminCompareStock />}
          />

          {/* Pre Order */}
          <Route
            path='pre-order/books/list'
            element={<AdminPreOrderBookList />}
          />
          <Route
            path='pre-order/books/list/details/:id'
            element={<AdminPreOrderBookRequest />}
          />
          <Route
            path='pre-order/books/create'
            element={<AdminCreatePreOrderBook />}
          />
          <Route
            path='pre-order/books/edit/:id'
            element={<AdminEditPreOrderBook />}
          />

          {/* Packing List */}
          <Route path='packing-list' element={<AdminPackingListIndex />} />
          <Route
            path='packing-list/details/:id'
            element={<AdminPackingListDetails />}
          />
          <Route path='group-item' element={<AdminGroupItemDetails />} />
          <Route path='group-item/create' element={<AdminGroupItemCreate />} />
          <Route path='group-item/edit/:id' element={<AdminGroupItemEdit />} />

          {/* Non Auto Count */}
          <Route path='non-autocount' element={<AdminNonAutoCountIndex />} />

          {/* Tracking */}
          <Route path='tracking-list' element={<AdminTrackingList />} />

          {/* Stock Check */}
          <Route
            path='stock-check-list'
            element={<AdminStockCheckListIndex />}
          />
          <Route
            path='stock-check/details/:id'
            element={<AdminStockCategory />}
          />
          <Route
            path='stock-check/er-shop/:id'
            element={<AdminErShopCheckList />}
          />
          <Route
            path='stock-check/ershop-mismatch-qty/:id'
            element={<AdminErMismatchQty />}
          />

          <Route
            path='stock-check/mobile/:id'
            element={<AdminMobileCheckList />}
          />
          <Route
            path='stock-check/mobile-mismatch-qty/:id'
            element={<AdminMobileMismatchQty />}
          />

          <Route
            path='stock-check/rack-box/:id'
            element={<AdminRackBoxSelect />}
          />
          <Route
            path='stock-check/store-room/:rackno/:boxno/:id'
            element={<AdminStoreRoomCheckList />}
          />
          <Route
            path='stock-check/store-room/mismatch-qty/:id'
            element={<AdminStoreRoomMismatchQty />}
          />
        </Route>

        {/* User */}
        <Route path='/user' element={<UserRoute />}>
          <Route path='dashboard' element={<UserDashboard />} />
          <Route path='packing-list' element={<UserPackingListIndex />} />
          <Route
            path='packing-list/details/:id'
            element={<UserPackingListDetails />}
          />
          <Route path='stock-check' element={<UserStockCategory />} />
          <Route
            path='stock-check/er-shop/:id'
            element={<UserErShopCheckList />}
          />
          <Route
            path='stock-check/mobile/:id'
            element={<UserMobileCheckList />}
          />
          <Route
            path='stock-check/store-room/:id'
            element={<UserRackBoxSelect />}
          />
          <Route
            path='stock-check/store-room/:rackno/:boxno/:id'
            element={<UserStoreRoomCheckList />}
          />
          {/* Pre Order */}
          <Route
            path='pre-order/books/list'
            element={<UserPreOrderBookList />}
          />
          <Route
            path='pre-order/books/list/details/:id'
            element={<UserPreOrderBookRequest />}
          />
        </Route>

        <Route path='/login' element={<Login />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
