import React from 'react'

const ReadOnlyRow = ({
  auth,
  itemCode,
  description,
  product,
  handleEditClick,
  handleStockQtyUpdate,
}) => {
  return (
    <div className='py-2' style={{ border: '1px solid #568e90' }}>
      {product?.acLocation && product.acLocation !== '[object Object]' ? (
        <span style={{ marginLeft: '15px' }}>{product.acLocation}</span>
      ) : (
        ''
      )}

      <span style={{ marginLeft: '15px' }}>
        Rack/Box: {product?.rackNo ? product.rackNo : ''}{' '}
        {product?.boxNo ? product.boxNo : ''}
      </span>

      <span style={{ marginLeft: '15px' }}>
        Qty: {product.quantity * product.uomRate}
      </span>
      {product.remark && (
        <span style={{ marginLeft: '15px' }}>Remark: {product.remark}</span>
      )}

      <span style={{ marginLeft: '15px' }}>
        {auth?.user?._id === '658e28cb173937c3f849f2ce' ||
        auth?.user?._id === '65960e5fc2b5e5e3557ff94f' ? (
          <>
            <span
              className='me-3'
              style={{ cursor: 'pointer' }}
              onClick={(e) => handleEditClick(e, product, itemCode)}
            >
              <i class='bi bi-pencil-square'></i>
            </span>
            <span
              style={{ cursor: 'pointer', color: 'green' }}
              onClick={(e) => handleStockQtyUpdate(e, product, description)}
            >
              <i class='bi bi-pen-fill'></i>
            </span>
          </>
        ) : (
          <span
            style={{ cursor: 'pointer', color: 'green' }}
            onClick={(e) => handleStockQtyUpdate(e, product, description)}
          >
            <i class='bi bi-pen-fill'></i>
          </span>
        )}
      </span>
    </div>
  )
}

export default ReadOnlyRow
