import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import FileSaver from 'file-saver'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const AdminExcelFile = () => {
  const navigate = useNavigate()
  const [stocklocationfile, setStocklocationfile] = useState(null)
  const [stockitemfile, setStockitemfile] = useState(null)
  const [stockListfile, setStockListfile] = useState(null)

  const [isLoadingLocation, setIsLoadingLocation] = useState(false) // Loading state for stock location
  const [isLoadingItem, setIsLoadingItem] = useState(false) // Loading state for stock item
  const [isLoadingStockList, setIsLoadingStockList] = useState(false) // Loading state for stock list

  const fileInputRefLocation = useRef(null) // Create a ref for the stock location file input
  const fileInputRefItem = useRef(null) // Create a ref for the stock item file input
  const fileInputRefStockList = useRef(null) // Create a ref for the stock list file input

  // Initial state for lastUpdatedAt for both models
  const [lastUpdatedAtLocation, setLastUpdatedAtLocation] = useState(null)
  const [lastUpdatedAtItem, setLastUpdatedAtItem] = useState(null)
  const [lastUpdatedAtStockList, setLastUpdatedAtStockList] = useState(null)

  const handleFileChangeLocation = (e) => {
    setStocklocationfile(e.target.files[0])
  }

  const handleFileChangeItem = (e) => {
    setStockitemfile(e.target.files[0])
  }

  const handleFileChangeStockList = (e) => {
    setStockListfile(e.target.files[0])
  }

  const importStockLocationExcel = async (e) => {
    e.preventDefault()
    if (!stocklocationfile) {
      alert('Please select Stock Location Excel file.')
      return
    }

    const formData = new FormData()
    formData.append('file', stocklocationfile)
    setIsLoadingLocation(true) // Start loading for location

    try {
      const response = await fetch('/api/v1/warehouse/import/stock-location', {
        method: 'POST',
        body: formData,
      })
      if (response.ok) {
        // Check if the upload was successful
        const result = await response.text()
        console.log(result)
        alert('Upload successful!')
        setLastUpdatedAtLocation(moment().format('YYYY-MM-DD HH:mm:ss')) // Update last updated time
        setStocklocationfile(null)
        fileInputRefLocation.current.value = '' // Reset the input element for location
      } else {
        throw new Error('Upload was not successful')
      }
    } catch (error) {
      console.error('Upload failed:', error)
      alert('Upload failed')
    }
    setIsLoadingLocation(false) // Stop loading after upload or on failure
  }

  const importStockItemExcel = async (e) => {
    e.preventDefault()
    if (!stockitemfile) {
      alert('Please select Stock Item Excel file.')
      return
    }

    const formData = new FormData()
    formData.append('file', stockitemfile)
    setIsLoadingItem(true) // Start loading for item

    try {
      const response = await fetch('/api/v1/warehouse/import/stock-item', {
        method: 'POST',
        body: formData,
      })
      if (response.ok) {
        // Check if the upload was successful
        const result = await response.text()
        console.log(result)
        alert('Upload successful!')
        setLastUpdatedAtItem(moment().format('YYYY-MM-DD HH:mm:ss')) // Update last updated time
        setStockitemfile(null)
        fileInputRefItem.current.value = '' // Reset the input element for item
      } else {
        throw new Error('Upload was not successful')
      }
    } catch (error) {
      console.error('Upload failed:', error)
      alert('Upload failed')
    }
    setIsLoadingItem(false) // Stop loading after upload or on failure
  }

  const importStockListExcel = async (e) => {
    e.preventDefault()
    if (!stockListfile) {
      alert('Please select Stock List Excel file.')
      return
    }

    const formData = new FormData()
    formData.append('file', stockListfile)
    setIsLoadingStockList(true) // Start loading for item

    try {
      const response = await fetch('/api/v1/warehouse/import/stock-list', {
        method: 'POST',
        body: formData,
      })
      if (response.ok) {
        // Check if the upload was successful
        const result = await response.text()
        console.log(result)
        alert('Upload successful!')
        setLastUpdatedAtStockList(moment().format('YYYY-MM-DD HH:mm:ss')) // Update last updated time
        setStockListfile(null)
        fileInputRefStockList.current.value = '' // Reset the input element for item
      } else {
        throw new Error('Upload was not successful')
      }
    } catch (error) {
      console.error('Upload failed:', error)
      alert('Upload failed')
    }
    setIsLoadingStockList(false) // Stop loading after upload or on failure
  }

  // useEffect to fetch last updated dates
  useEffect(() => {
    const fetchLastUpdatedAt = async (endpoint, setter) => {
      try {
        const response = await fetch(endpoint)
        const data = await response.json()
        if (data.lastUpdatedAt) {
          setter(moment(data.lastUpdatedAt).format('YYYY-MM-DD HH:mm:ss'))
        }
      } catch (error) {
        console.error('Failed to fetch last updated date:', error)
      }
    }

    fetchLastUpdatedAt(
      '/api/v1/warehouse/updated-date/stock-location',
      setLastUpdatedAtLocation
    )
    fetchLastUpdatedAt(
      '/api/v1/warehouse/updated-date/stock-item',
      setLastUpdatedAtItem
    )
    fetchLastUpdatedAt(
      '/api/v1/warehouse/updated-date/stock-list',
      setLastUpdatedAtStockList
    )
  }, [])

  const downloadExcel = async (e) => {
    e.preventDefault()
    try {
      await fetch('/api/v1/warehouse/download-stock-record')
        .then((res) => {
          return res.blob()
        })
        .then((blob) => {
          return FileSaver.saveAs(
            blob,
            `${moment().format('YYYYMMDD_HHmm')}_stock.xlsx`
          )
        })
    } catch (error) {
      console.log(error)
    }
  }

  // Simple CSS Spinner
  const Spinner = () => (
    <div className='spinner-border text-primary' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )

  return (
    <Layout>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={() => navigate('/admin/warehouse')}
          >
            Return
          </button>
        </div>
        <div className='p-3' style={{ border: '1px solid #4da3ff' }}>
          <label className='fw-bold mb-2'>
            Upload Stock Item (Last Updated at:{' '}
            {lastUpdatedAtItem || 'Not available'})
          </label>
          <input
            type='file'
            className='form-control'
            onChange={handleFileChangeItem}
            ref={fileInputRefItem}
          />
          <div className='text-center mt-2'>
            {isLoadingItem ? (
              <Spinner />
            ) : (
              <button
                type='button'
                className='btn btn-warning btn-sm'
                style={{ color: '#fff' }}
                onClick={importStockItemExcel}
              >
                Upload
              </button>
            )}
          </div>
        </div>

        <div className='p-3' style={{ border: '1px solid #4da3ff' }}>
          <label className='fw-bold mb-2'>
            Upload Stock Location (Last Updated at:{' '}
            {lastUpdatedAtLocation || 'Not available'})
          </label>
          <input
            type='file'
            className='form-control'
            onChange={handleFileChangeLocation}
            ref={fileInputRefLocation}
          />
          <div className='text-center mt-2'>
            {isLoadingLocation ? (
              <Spinner />
            ) : (
              <button
                type='button'
                className='btn btn-warning btn-sm'
                style={{ color: '#fff' }}
                onClick={importStockLocationExcel}
              >
                Upload
              </button>
            )}
          </div>
        </div>

        <div className='p-3' style={{ border: '1px solid #4da3ff' }}>
          <label className='fw-bold mb-2'>
            Upload Stock List (Last Updated at:{' '}
            {lastUpdatedAtStockList || 'Not available'})
          </label>
          <input
            type='file'
            className='form-control'
            onChange={handleFileChangeStockList}
            ref={fileInputRefStockList}
          />
          <div className='text-center mt-2'>
            {isLoadingStockList ? (
              <Spinner />
            ) : (
              <button
                type='button'
                className='btn btn-warning btn-sm'
                style={{ color: '#fff' }}
                onClick={importStockListExcel}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AdminExcelFile
